import React, { useEffect, useRef, useState } from "react";
import "./Login.scss";
import "./Pago.scss";

import { useLanguageContext } from "../components/context/LanguageContext";
import Loading from "../components/infrastructure/loadingAnimation";
import browserHistory from "../components/infrastructure/browserHistory";
import httpClient from "../Utils/AxiosHttpClient";
import MainBackgroundImage from "../assets/img/GirlLookingUp.jpeg";
import generateEndpointUrl from "../Utils/apiEndpointUrls";
import { Nav } from "./common/Nav";
import Modal from "./common/modal/Modal";
import useTranslation, {
  useImagesTranslation,
} from "./translations/translations";
import { useAuth } from "../hooks/AuthProvider";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { getDataLayer } from "../Utils/dataLayer";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { CLIENT_ID } from "../Utils/paypalConfiguration";
const tagManagerArgs = getDataLayer("payment");
export default function Pago() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [displayError, setDisplayError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [alertType, setAlertType] = useState("danger");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [emailConfirm, setEmailConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [prices, setPrices] = useState({ es: 0, en: 0 });
  const emailRef = useRef(null);
  const translations = useTranslation();
  const images = useImagesTranslation();
  const [id, setId] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const { language } = useLanguageContext();
  const [alertMessage, setAlertMessage] = useState("");
  const [submitForm, setSubmitForm] = useState(false);
  const [captchaValue, setCaptchaValue] = useState();
  const [orderId, setOrderId] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const showNav = false;
  const [areTermsAndConditionsChecked, setAreTermsAndConditionsChecked] =
    useState(false);
  const [errors, setErrors] = useState({
    name: false,
    cardNumber: false,
    expirationMonth: false,
    expirationYear: false,
    cvv: false,
    email: false,
    emailConfirm: false,
    emailDontMatch: false,
    applicantName: false,
  });
  const [isValidCoupon, setisValidCoupon] = useState(0);
  const [coupon, setCoupon] = useState("");
  TagManager.dataLayer(tagManagerArgs);

  async function submittedForm() {
    try {
      if (!isFormValid()) {
        return;
      }
      if (
        captchaValue === undefined ||
        captchaValue === "" ||
        captchaValue === null
      ) {
        showAlert(translations.payment.catpchaError);
        return;
      }
      setLoading(true);
      setSubmitForm(true);
    } catch (err) {
      showAlert(translations.payment.error);
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const clearError = (name) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  const numeric = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const isFormValid = () => {
    const err = { ...errors };
    err.applicantName = !applicantName || applicantName === "";
    err.email = !email || email === "";
    err.emailConfirm = !emailConfirm || emailConfirm === "";
    err.emailDontMatch = emailConfirm.toLowerCase() !== email.toLowerCase();
    !areTermsAndConditionsChecked &&
      showAlert(translations.login.acceptTermsAndConditions);
    if (err.emailDontMatch) {
      err.email = true;
      err.emailConfirm = true;
    }
    setErrors(err);
    return (
      !err.fullName &&
      !err.email &&
      !err.emailConfirm &&
      !err.emailDontMatch &&
      !err.applicantName &&
      areTermsAndConditionsChecked
    );
  };

  const onChangeCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const formButtonClicked = async (e) => {
    try {
      e.preventDefault();
      if (!isFormValid()) {
        setLoading(false);
        return;
      }
      setShowPayment(true);
      //await submittedForm();
    } catch (e) {
      console.log("ERR CLIK: ", e);
    }
  };

  const buyTest = async (e) => {
    try {
      e.preventDefault();
      if (
        captchaValue === undefined ||
        captchaValue === "" ||
        captchaValue === null
      ) {
        showAlert(translations.payment.catpchaError);
        return;
      }
      setLoading(true);
      setSubmitForm(true);
    } catch (e) {
      console.log("ERR CLIK: ", e);
    }
  };

  async function getAssessment() {
    const path = document.location.pathname;
    const testId = path.substring(path.lastIndexOf("/") + 1);
    if (testId === "purchase" || testId === "") {
      setDisplayError(true);
      return;
    }
    setId(testId);
    const url = `${generateEndpointUrl("UserAssessment")}/${testId}`;
    const response = await httpClient.getData(url);
    if (response.status === 200 || response.data || response.data?.length > 0) {
      if (!response.data.completed || response.data.isPaid) {
        setDisplayError(true);
        return;
      }

      return;
    }
    setDisplayError(true);
    return;
  }
  async function getPrices() {
    const url = `${generateEndpointUrl("Settings")}/prices`;
    let response = await httpClient.getData(url);
    if (response.status === 200 || response.data) {
      setPrices(response.data);
      return response.data;
    }

    return undefined;
  }

  useEffect(() => {
    if (orderId !== "") console.log("Useeffect: " + orderId);
  }, [orderId]);

  const createOrder = async () => {
    const url = `${generateEndpointUrl("Paypal")}/${language}`;
    var response = await httpClient.postData(url);
    if (response.status === 200 || response.data) {
      console.log("Create: " + response.data);
      setOrderId(response.data);
      return response.data;
    }
  };

  // check Approval
  const onApprove = (data, actions) => {
    console.log("onApprobe");
    console.log(data);
    setOrderId(data.orderID);
    /*console.log("id: " + id);
    const url = `${generateEndpointUrl("Paypal")}/${data.orderID}/capture`;
    httpClient.postData(url).then((response) => {
      if (response.status === 200 || response.data) {
        console.log(response);
        alert("correcto " + orderId);
      }
    });*/
    setSubmitForm(true);
  };

  async function validateCoupon() {
    if (coupon === "") {
      setisValidCoupon(2);
      return;
    }
    const url = `${generateEndpointUrl("Settings")}/coupons/${coupon}`;
    let response = await httpClient.getData(url);
    if (response.status === 200 || response.data) {
      setisValidCoupon(1);
      setPrices({ es: 0, en: 0 });
      return true;
    }
    if (prices.en === 0 || prices.es === 0) getPrices();
    setisValidCoupon(2);
    return false;
  }

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }, [showError]);

  useEffect(() => {
    if (displayError) {
      setTimeout(() => {
        browserHistory.push("/welcome");
      }, 10000);
    }
  }, [displayError]);

  useEffect(() => {
    try {
      setLoading(true);
      const getData = async () => {
        getAssessment();
      };
      getData();
      if (prices.es === 0 && prices.en === 0) {
        const setPricing = async () => {
          await getPrices();
        };
        setPricing();
      }
    } catch (e) {
      console.log("Exception at :", e);
    } finally {
      setLoading(false);
      toTop();
    }
    // eslint-disable-next-line
  }, []);
  const [continueWithPayment, setContinueWithPayment] = useState(false);
  useEffect(() => {
    console.log("OnUseEffect submitform: " + submitForm);
    if (submitForm) {
      const register = async () => {
        try {
          setLoading(true);
          const _auth = auth;
          let name = applicantName;
          var response = await _auth.registerByEmail(
            email,
            emailConfirm,
            id,
            language,
            name
          );
          if (response.status === 400) {
            setSubmitForm(false);
            showAlert(translations.payment.checkData);
            const err = { ...errors };
            err.email = true;
            err.emailConfirm = true;
            setErrors(err);
            return;
          }
          if (response.status === 404) {
            setSubmitForm(false);
            setDisplayError(true);
            return;
          }
          if (response.status === 200) {
            setSubmitForm(false);
            setContinueWithPayment(true);
            return;
          }

          showAlert(translations.payment.error);
          return;
        } catch (e) {
          console.log(e);
          showAlert(translations.payment.error);
          return;
        } finally {
          setLoading(false);
        }
      };

      register();
    }
    // eslint-disable-next-line
  }, [submitForm]);

  useEffect(() => {
    console.log("useeffect continueWithPayment: " + continueWithPayment);
    if (continueWithPayment) {
      const submitInformation = async () => {
        try {
          setLoading(true);
          let name = applicantName;
          const url = `${generateEndpointUrl("Payment")}Purchase`;
          const response = await httpClient.postData(url, {
            PaymentMethodId: orderId,
            fullName: name,
            email: email,
            language,
            assessmentId: id,
            couponCode: coupon,
          });
          if (response.status === 400) {
            setSubmitForm(false);
            setContinueWithPayment(false);
            showAlert(translations.payment.checkData);
            const err = { ...errors };
            err.email = true;
            err.emailConfirm = true;
            setErrors(err);
            return;
          }
          if (response.status === 402) {
            setSubmitForm(false);
            setContinueWithPayment(false);
            showAlert(translations.payment.invalidCoupon);
            setisValidCoupon(2);
            return;
          }
          if (response.status === 404) {
            setSubmitForm(false);
            setContinueWithPayment(false);
            setDisplayError(true);
            return;
          }
          if (
            response.status !== 200 ||
            !response.data ||
            response.data.length <= 0
          ) {
            let errorMsg = translations.payment.paymentErrorMsg;
            showAlert(errorMsg);
            return;
          }
          setLoading(false);
          setDisplaySuccess(true);
        } catch (e) {
          console.log(e);
          showAlert(translations.payment.error);
        } finally {
          setLoading(false);
          setContinueWithPayment(false);
          setSubmitForm(false);
        }
      };

      submitInformation();
    }
    // eslint-disable-next-line
  }, [continueWithPayment]);

  useEffect(() => {
    if (displaySuccess) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const getResultsHere = async () => {
        setLoading(true);
        const url = `${generateEndpointUrl(
          "UserAssessment"
        )}/GetResults/${language}/1/${id}`;
        await httpClient.getFile(url, `${email}.pdf`);
        await delay(5000);
        setDisplaySuccess(false);
        setLoading(false);
        setTimeout(() => {
          browserHistory.push("/");
        }, 15 * 1000);
      };
      getResultsHere();
    }
    // eslint-disable-next-line
  }, [displaySuccess]);

  const showAlert = (message, type) => {
    type = type === undefined ? "danger" : type;
    setAlertMessage(message);
    setAlertType(type);
    setShowError(true);
  };
  function toTop() {
    document.querySelector("#pagoContainer").scrollTo(0, 0);
  }
  return (
    <>
      <div id="pagoContainer"></div>
      <div
        className="container-fluid account payment"
        style={{
          background: `url(${MainBackgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        {loading && <Loading />}
        {
          <div className="row">
            <div className="col-12">
              <div
                className={`alert alert-${alertType} fade ${
                  showError ? "show" : ""
                }`}
                role="alert"
              >
                {alertMessage}
              </div>
            </div>
          </div>
        }
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 left-side  payment-content">
            <form id="form-checkout">
              <div className="row">
                {showNav && <Nav dark={true} className="d-lg-none d-xl-none" />}
                <div className="row">
                  <div className="col-12 logo-container">
                    <h2>
                      {translations.payment.title}
                      <span className="manropeLight">resilien</span>
                      <span className="poppinsbold">TEST</span>
                      <span className="manropeLight text-bottom">&reg;</span>
                    </h2>
                    <p>{translations.payment.title2}</p>
                  </div>
                </div>
                <div className="row message-container">
                  <div className="d-block d-sm-flex d-xs-flex d-md-flex d-lg-none d-xl-none logo-container col-12 d-flex justify-content-center align-items-center">
                    <p className="mf black">
                      <strong>
                        {translations.payment.responsiveMessage.replace(
                          "@price",
                          prices[language]
                        )}
                      </strong>
                    </p>
                  </div>
                </div>
                {!showPayment && (
                  <>
                    <div className="row">
                      <div className="col-lg-6 col-md-10 col-sm-8 col-xs-12">
                        <label>{translations.payment.applicantName}</label>
                        <input
                          className={`form-control  ${
                            errors.applicantName ? "is-invalid" : ""
                          }`}
                          placeholder={
                            translations.payment.applicantNamePlaceHolder
                          }
                          type="text"
                          name="applicantName"
                          value={applicantName}
                          onChange={(e) => {
                            clearError("applicantName");
                            setApplicantName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mt-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <label>{translations.payment.email}</label>
                        <input
                          ref={emailRef}
                          id="form-checkout__cardholderEmail"
                          className={`form-control  ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          placeholder={translations.payment.emailPlaceholder}
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => {
                            clearError("email");
                            clearError("emailDontMatch");
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div className="mt-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <label>{translations.payment.emailConfirm}</label>
                        <input
                          className={`form-control  ${
                            errors.emailConfirm ? "is-invalid" : ""
                          }`}
                          placeholder={
                            translations.payment.emailConfirmPlaceHolder
                          }
                          type="email"
                          name="emailConfirm"
                          value={emailConfirm}
                          onChange={(e) => {
                            clearError("emailConfirm");
                            clearError("emailDontMatch");
                            setEmailConfirm(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {errors.emailDontMatch && (
                      <div className="row">
                        <div className="col-12">
                          <p className="error">
                            {translations.payment.emailDifferent}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 mt-3">
                        <p>{translations.payment.emailNote}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>
                          <input
                            className="margin-right-10px"
                            type="checkbox"
                            checked={areTermsAndConditionsChecked}
                            onChange={(e) => {
                              setAreTermsAndConditionsChecked(e.target.checked);
                            }}
                          />
                          {translations.login.accept1}{" "}
                          <a target="blank" href="/aviso-de-privacidad.pdf">
                            {translations.login.accept2}
                          </a>{" "}
                          {translations.login.accept3}{" "}
                          <span className="link">
                            {" "}
                            {translations.login.accept4}.
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                        <button
                          type="submit"
                          onClick={(e) => formButtonClicked(e)}
                          className="boton3 align-items-center justify-content-center"
                        >
                          {translations.payment.continueWithPayment}
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {showPayment && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <label
                          className="orange clickable"
                          onClick={() => setShowPayment(false)}
                        >
                          {translations.payment.back}
                        </label>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6 col-md-10 col-sm-8 col-xs-12">
                        <label>{translations.payment.coupon}</label>
                        <input
                          id="form-checkout__cardholderEmail"
                          className={`form-control ${
                            isValidCoupon === 1
                              ? "is-valid"
                              : isValidCoupon === 2
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder={translations.payment.couponPlaceHolder}
                          type="text"
                          name="coupon"
                          onChange={(e) => {
                            setisValidCoupon(0);
                            setCoupon(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center pt-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            validateCoupon();
                          }}
                        >
                          {translations.payment.couponValidate}
                        </button>
                      </div>
                    </div>

                    {isValidCoupon === 1 && (
                      <>
                        <div className="row">
                          <div className="col-12 mt-3">
                            <ReCAPTCHA
                              sitekey="6LdxabwkAAAAAJMbZPPQw3FcJCuPAp8cd33Bq9EB"
                              onChange={onChangeCaptcha}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                            <button
                              type="submit"
                              onClick={(e) => buyTest(e)}
                              className="boton3 align-items-center justify-content-center"
                            >
                              {translations.payment.comprarTest}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {isValidCoupon !== 1 && (
                      <div className="row">
                        <div className="col-12 mt-4">
                          <PayPalScriptProvider
                            options={{
                              "client-id": CLIENT_ID,
                              currency: translations.payment.currency,
                            }}
                          >
                            <PayPalButtons
                              style={{ layout: "vertical", shape: "pill" }}
                              createOrder={createOrder}
                              onApprove={onApprove}
                            ></PayPalButtons>
                          </PayPalScriptProvider>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="row price-containter mt-4 ">
                  <div className="d-block d-sm-flex d-xs-flex d-md-flex d-lg-none d-xl-none logo-container col-12 d-flex justify-content-center align-items-center">
                    <p className="sm">
                      {translations.payment.title}
                      <span className="manropeLight">resilien</span>
                      <span className="poppinsbold">TEST</span>
                      <span className="manropeLight text-bottom">&reg;</span>
                      {` $${prices[language]} ${
                        translations.payment.currency
                      } ${
                        prices[language] > 0
                          ? translations.payment.vatMessage
                          : ""
                      }`}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="logo-container col-12 d-flex justify-content-center align-items-center">
                    <p className="small-font">
                      {translations.payment.localCurrency}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="logo-bottom col-12 mt-3 d-flex justify-content-center align-items-center">
                    <img
                      src="/img/Compra_protect.png"
                      alt="compra protect resilientest"
                    ></img>
                  </div>
                </div>
                <div className="row">
                  <div className="logo-bottom col-12 d-flex justify-content-center align-items-center">
                    <img src="/img/Logo con linea.png" alt="" />
                  </div>
                </div>
                <select
                  style={{ display: "none" }}
                  name="issuer"
                  id="form-checkout__issuer"
                ></select>
                <select
                  style={{ display: "none" }}
                  name="identificationType"
                  id="form-checkout__identificationType"
                ></select>
                <input
                  style={{ display: "none" }}
                  type="text"
                  name="identificationNumber"
                  id="form-checkout__identificationNumber"
                />
                <select
                  style={{ display: "none" }}
                  name="installments"
                  id="form-checkout__installments"
                ></select>
              </div>
            </form>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12-col col-xs-12 right-side">
            {showNav && (
              <Nav dark={true} className="d-none d-lg-flex d-xl-flex" />
            )}
            <div className="row price-containter d-none d-lg-flex d-xl-flex pt-5">
              <div className="logo-container col-12 d-flex justify-content-center align-items-center">
                <h3>
                  {translations.payment.title}
                  <span className="manropeLight">resilien</span>
                  <span className="poppinsbold">TEST</span>
                  <span className="manropeLight text-bottom">&reg;</span>
                  {` $${prices[language]} ${translations.payment.currency} ${
                    prices[language] > 0 ? translations.payment.vatMessage : ""
                  }`}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="logo-container col-12 d-flex justify-content-center align-items-center">
                <p className="small-font">
                  {translations.payment.localCurrency}
                </p>
              </div>
            </div>
            <div className="row section4">
              <div className="px-0 col-md-2 col-6 d-flex justify-content-center align-items-center">
                <div className="preview-img">
                  <img alt="sample1" src={images.sample1}></img>
                </div>
              </div>
              <div className="col-md-2 col-6 px-0 d-flex justify-content-center align-items-center">
                <div className="preview-img">
                  <img alt="sample1" src={images.sample2}></img>
                </div>
              </div>
              <div className="col-md-2 grid col-6 px-0 d-flex justify-content-center align-items-center">
                <div className="preview-img">
                  <img alt="sample1" src={images.sample3}></img>
                </div>
              </div>
              <div className="col-md-2 grid col-6 px-0 d-flex justify-content-center align-items-center">
                <div className="preview-img">
                  <img alt="sample1" src={images.sample4}></img>
                </div>
              </div>
              <div className="col-md-2 grid col-6 px-0 d-flex justify-content-center align-items-center">
                <div className="preview-img">
                  <img alt="sample1" src={images.sample5}></img>
                </div>
              </div>
              <div className="col-md-2 grid col-6 px-0 d-flex justify-content-center align-items-center">
                <div className="preview-img">
                  <img alt="sample1" src={images.sample6}></img>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 logo-container">
                  <p className="manropeBold">
                    {translations.payment.resilientQuestion}
                    <span className="manropeLight">resilien</span>
                    <span className="poppinsbold">TEST</span>
                    <span className="manropeLight text-bottom">&reg;</span>?
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>{translations.payment.resilientListTitle}</p>
                  <ul className="resilient-list">
                    <li className="disc-list">
                      {translations.payment.resilientList1}
                    </li>
                    <li className="disc-list">
                      {translations.payment.resilientList2}
                    </li>
                    <li className="disc-list">
                      {translations.payment.resilientList3}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={displayError}
            modalClosed={() => browserHistory.push("/Welcome")}
          >
            <div>{translations.payment.assessmentNotAnswered}</div>
          </Modal>
          <Modal
            show={displaySuccess}
            modalClosed={() => {
              setDisplaySuccess(false);
            }}
          >
            <div>{translations.payment.successPaymentMsg}</div>
          </Modal>
        </div>
      </div>
    </>
  );
}
