import React, { useState } from "react";
import "./Login.scss";
import authConfig from "../authorization/auth-config";
import Loading from "../components/infrastructure/loadingAnimation";
import AxiosHttpClient from "../Utils/AxiosHttpClient";
import MainBackgroundImage from "../assets/img/GirlHand.png";
import { Nav } from "./common/Nav";
import { useAuth } from "../hooks/AuthProvider";
import { useHistory } from "react-router-dom";
import useTranslation from "./translations/translations";
import { useLanguageContext } from "./context/LanguageContext";
import TagManager from "react-gtm-module";
import { getDataLayer } from "../Utils/dataLayer";
const tagManagerArgs = getDataLayer("login");
export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabledForgotPasswordLink, setIsDisabledForgotPasswordLink] =
    useState(false);
  const [displayPasswordSuggestion, setDisplayPasswordSuggestion] =
    useState(false);
  const auth = useAuth();
  const history = useHistory();
  const { language } = useLanguageContext();
  const translations = useTranslation();

  const login = async (isLoginIn) => {
    if (isLoginIn === false) {
      alert(translations.login.acceptTermsAndConditions);
      return;
    }
    if (isLoginIn === false && !isValidPassword(password)) {
      setDisplayPasswordSuggestion(true);
      return;
    }
    const _auth = auth; // why we need a try catch if we are handling api errros ???
    try {
      setLoading(true);
      if (email && password) {
        let response;
        if (isLoginIn === true) {
          response = await _auth.login({ email, password });
        } else {
          response = await _auth.register(
            { email, password },
            language.toUpperCase()
          );
        }
        if (response?.status === 200) {
          authConfig.authToken = response.data.token;
          AxiosHttpClient.refreshAuthToken();
          if (response.data.isAdmin) {
            history.push("/admin");
            return;
          }
          history.push("/Account");
        } else {
          alert(translations.login.invalidEmailOrPassword);
          return;
        }
      }
    } catch (e) {
      console.log("Error at login: ", e);
      setLoading(false);
    } finally {
    }
  };

  function isValidPassword(password) {
    if (!password || password.length < 8) {
      return false;
    }

    var lowerCaseLetters = /[a-z]/;
    if (!password.match(lowerCaseLetters)) {
      console.log("lower");
      return false;
    }

    var upperCaseLetters = /[A-Z]/;
    if (!password.match(upperCaseLetters)) {
      console.log("upper");
      return false;
    }

    var numbers = /[0-9]/;
    if (!password.match(numbers)) {
      console.log("digit");
      return false;
    }

    var specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/;
    if (!password.match(specialChars)) {
      console.log("char");
      return false;
    }

    return true;
  }

  async function sendForgotPasswordEmail() {
    if (isDisabledForgotPasswordLink) {
      return;
    }
    if (!email || email.length < 6) {
      alert(translations.login.addEmailToResetPassword);
      return;
    }
    const resetConfirmMsg = translations.login.resetConfirmMsg.replace(
      "{0}",
      email
    );
    const confirmResult = window.confirm(resetConfirmMsg);
    if (!confirmResult) {
      return;
    }
    setLoading(true);
    try {
      const response = await AxiosHttpClient.postData(
        `api/Account/${language.toUpperCase()}/ForgotPassword?userEmail=${email}`
      );
      if (!response || response.status !== 200) {
        alert(translations.login.errorOnResetPassword);
        return;
      }

      alert(translations.login.resetPasswordEmailSent);
      setIsDisabledForgotPasswordLink(true);
    } catch (e) {
      console.log("Exception at ssdf: ", e);
    } finally {
      setLoading(false);
    }
  }
  TagManager.dataLayer(tagManagerArgs);
  return (
    <div
      id="main-content"
      className="container-fluid account"
      style={{
        background: `url(${MainBackgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      {loading && <Loading />}
      <div className="row login-content">
        <div className="col-md-6 col-12 left-side">
          <div className="row">
            <Nav dark={true} className="d-md-none " />
            <div className="col-12">
              <h2 className="my-0 my-md-3 text-center text-uppercase">
                {translations.login.welcome}
              </h2>
            </div>
            <div>
              <div className="row">
                <div className="col-11">
                  <label className="manropeBold label">
                    {translations.login.mail}
                  </label>
                  <input
                    className="form-control"
                    placeholder={translations.login.mailplaceholder}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-11">
                  <label className="manropeBold label">
                    {translations.login.password}
                  </label>
                  <input
                    className="form-control"
                    placeholder={translations.login.passwordplaceholder}
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setDisplayPasswordSuggestion(false);
                    }}
                  />
                </div>
                <div
                  className="col-1 d-flex align-items-center mt-3"
                  onClick={() => alert(translations.login.passwordHint)}
                >
                  <div
                    title={translations.login.passwordHint}
                    style={{ paddingTop: "5px" }}
                  >
                    <b>&#9432;</b>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row col-12"
              style={{
                display: displayPasswordSuggestion === true ? "" : "none",
              }}
            >
              <label style={{ fontSize: "smaller", color: "red" }}>
                {translations.login.passwordHint}
              </label>
            </div>
            <div className="mt-3" onClick={sendForgotPasswordEmail}>
              <div style={{ textAlign: "center" }}>
                <span className="underline link">
                  {translations.login.forgot}
                </span>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span
                    className="boton2 align-items-center justify-content-center"
                    onClick={() => login(true)}
                  >
                    {translations.login.login}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-center logo-container">
                <div className="line"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-8 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center logo-container">
                <h1>
                  <span className="manropeLight">resilien</span>
                  <span className="poppinsbold">TEST</span>
                </h1>
                <p className="manropeLight rights pt-4">&reg;</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <Nav dark={true} className="d-none d-md-flex " />
        </div>
      </div>
    </div>
  );
};
