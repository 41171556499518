//import React, { useState, useContext } from "react";
import LocalizedStrings from "react-localization";
import localization from "../../localization";
import { useLanguageContext } from "../context/LanguageContext";
import Ayuda from "../../assets/icons/AyudaWhatsapp.png";
import Ayuda_En from "../../assets/icons/AyudaWhatsapp-EN.png";
import Ejemplo1 from "../../assets/img/Sample1.png";
import Ejemplo2 from "../../assets/img/Sample2.png";
import Ejemplo3 from "../../assets/img/Sample3.png";
import Ejemplo4 from "../../assets/img/Sample4.png";
import Ejemplo5 from "../../assets/img/Sample5.png";
import Ejemplo6 from "../../assets/img/Sample6.png";
import EjemploEng1 from "../../assets/img/Sample1eng.png";
import EjemploEng2 from "../../assets/img/Sample2Eng.png";
import EjemploEng3 from "../../assets/img/Sample3Eng.png";
import EjemploEng4 from "../../assets/img/Sample4Eng.png";
import EjemploEng5 from "../../assets/img/Sample5Eng.png";
import EjemploEng6 from "../../assets/img/Sample6eng.png";
import closeButton from "../../assets/icons/BotonCerrar.png";
import closeButtonEN from "../../assets/icons/BotonCerrar_EN.png";

export default function useTranslation() {
  const { language } = useLanguageContext();
  let translation = new LocalizedStrings(localization);

  translation.setLanguage(language);
  return translation;
}
export function useImagesTranslation() {
  const { language } = useLanguageContext();
  let images = {
    help: language === "en" ? Ayuda_En : Ayuda,
    close: language === "en" ? closeButtonEN : closeButton,
    sample1: language === "en" ? EjemploEng1 : Ejemplo1,
    sample2: language === "en" ? EjemploEng2 : Ejemplo2,
    sample3: language === "en" ? EjemploEng3 : Ejemplo3,
    sample4: language === "en" ? EjemploEng4 : Ejemplo4,
    sample5: language === "en" ? EjemploEng5 : Ejemplo5,
    sample6: language === "en" ? EjemploEng6 : Ejemplo6,
  };

  return images;
}

export function useGetLenguage() {
  const { language } = useLanguageContext();
  return language;
}