const strings = {
  /** Common **/
  resilien: "resilien",
  test: "TEST",
  now: "now",
  begin: "do the ",
  begin_2: "do the test",
  in: "",
  here: "here",
  footer: "©2022 Resilientest. All rights reserved.",
  by: "By",
  pls_login: "Pleas Login",
  success: "Data saved succesfully!",
  fail: "There is an error when saving, pls try again.",
  whatsappHelpMsg: "Hello, I need help with resilientest",
  /** End Common **/

  /** Hamburguer **/
  h_myaccount: "My Account",
  h_myshopping: "My Purchases",
  h_logout: "Log Out",
  h_login: "Log In",
  h_register: "SIgn Up",
  /** End Hamburguer **/

  /** Home Page **/
  home_infoHelp:
    "The diagnosis that will help you discover your level of Resilience.",
  home_quote:
    "It is not the strongest of the species that survive, nor the most intelligent. The most flexible and adaptable to change survive.",
  home_author: "Charles Darwin",
  home_question: "what is resilience?",
  home_quote2: "And when you become a diamond, you will know why life ",
  home_quote2_2: "had to pressure you...",
  home_text:
    "<span class='f-18 manropeBold'>Resilience</span> is the ability to bounce back from setbacks and be able to move on, and even stronger in the process.",
  home_text_2:
    "Being clear about how you think, feel and act in the face of the challenges that come your way in life (personal, family, professional, etc) you have the opportunity to solve, anticipate and strengthen.",
  home_text_3:
    "measures your reaction mechanism by identifying where your strengths and areas of opportunity lie against seven specific dimensions.",
  home_square_title: "self confidence",
  home_square_text:
    "Realistic and positive beliefs and attitudes about one’s self and individual ability to influence the environment for the best.",
  home_square_title_2: "Adaptability",
  home_square_text_2:
    "Ability to make one’s approach to every situation more flexible in order to create new ideas and solutions.",
  home_square_title_3: "Determination",
  home_square_text_3:
    "Ability to develop and to maintain clear goals and values.",
  home_square_title_4: "Connection",
  home_square_text_4:
    "Ability to keep strong long term bonds with significant people in one’s life. To develop and maintain positive relations.",
  home_square_title_5: "Positivity",
  home_square_text_5:
    "Ability to create positive emotions by focusing on the good things of every experience. Gratitude is the key value of this dimension.",
  home_square_title_6: "Self care",
  home_square_text_6:
    "Taking responsibility for personal wellbeing and acknowledging the importance of self-care. Ability to develop physical and mental health strategies.",
  home_square_title_7: "self-transcendence",
  home_square_text_7:
    "Connection with what’s important and what gives meaning to our lives. This is a dynamic process that implies adaptability and trust in the environment, therefore creating coping mechanisms.",
  home_why: "WHY DO YOU NEED THIS...",
  home_why_2: "AND WHY DO YOU NEED IT NOW?",
  home_generic: "when making your",
  home_generic_2: "you will learn to:",
  home_banner_title: "DISCOVER",
  home_banner_text: "the power you have within you to be better every day.",
  home_banner_title_2: "KNOW",
  home_banner_text_2:
    "the attitudes and behaviors that help you improve and those that don't.",
  home_banner_text_2_2:
    "Start tracing the path of change according to the results of your test!",
  home_banner_title_3: "TRANSFORM",
  home_banner_text_3:
    "the adversities into opportunities and reach the best version of you.",
  home_banner_text_3_2:
    "You will have a series of tips that will allow you to improve your level in each of the 7 dimensions!",
  home_question_2: "Don't know how to start?",
  home_doIt: "DO IT NOW",
  home_click: "click",
  home_answer: "to answer the",
  home_question_3: "WHAT DO YOU RECEIVE IF YOU TAKE THE TEST? ",
  home_desc:
    "A report with the interpretation of your level of resilience in each of the 7 dimensions",
  home_see_report: "To see the full report,",

  /** End Home Page **/
  /** steps Page **/
  steps_start: "Home",
  steps_question: "What is ResilienceTEST?",
  steps_desc:
    "ResilienTEST is a self-assessment that will help you identify the level of resilience you have and how you present it in different angles or dimensions of your life.",
  steps_desc_2:
    "n addition to obtaining your results, you will also identify your strengths and areas of opportunity in each of the dimensions and you will obtain resources, tips and activities to better understand yourself and develop your resilience.",
  steps_desc_3:
    "The application consists of a series of multiple choice questions in which you will have to choose the best answer that defines your behavior. This application lasts about 30 minutes.",
  steps_see_results: "If you want to get your results:",
  steps_step1:
    "Register your data: Your email is very important for receiving your results.",
  steps_step2: "Proceed to payment.",
  steps_step3: "Wait in your email for the link to access the application.",
  steps_step4: "Solve the quiz.",
  steps_step5: "Download your results and receive it in your email.",
  steps_step6: "Contact us for any questions.",
  steps_see_delivery: "See the deliverable here:",
  steps_see_sample: "Sample Report",
  steps_proceed: "Proceed to purchase",
  /** End Account Page **/
  completelyDisagree: "Completely disagree",
  disagree: "Disagree",
  agree: "Agree",
  completelyAgree: "Completely agree",
  welcomeMessage: "WELCOME TO ",
  instructions: "Instructions",
  instructionsTest:
    "Answer the following questions according to your perception of yourself. There are not correct or incorrect answers.",
  questions: "questions",
  timeAmount: "Approximate time: ",
  minutes: "minutes",
  beginTest: "Begin the TEST",
  assessmentButtons: {
    previous: "Previous",
    next: "Next",
    endTest: "End Test",
  },
  successModal: {
    success: "resilienTEST completed successfully!",
    completionMessage: "You can login to see your results on main page.",
  },
  warningModal: {
    questionNeedsAnswer: "You need to answer all the questions,",
    continueMessage: "Do it to continue!",
  },
  demographics: {
    ages: [
      "Select an option",
      "Les than 18 years",
      "Between 18 y 25",
      "Between 26 y 35",
      "Between 36 y 45",
      "Between 46 y 55",
      "Between 56 y 65",
      "More than 65 years",
    ],
    genders: ["Select an option", "Male", "Female", "Other"],
    nationality: [
      "Select an option",
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Côte d`Ivoire",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo (Congo-Brazzaville)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czechia (Czech Republic)",
      "Democratic Republic of the Congo",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      'Eswatini (fmr. "Swaziland")',
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Holy See",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar (formerly Burma)",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Korea",
      "North Macedonia",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Korea",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States of America",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
    professionPlaceHolder: "what do you do for a living?",
    description:
      "Your results are almost ready, plase fill the information below to finish",
  },
  results: {
    header: "You have completed your ",
    resultsDescription: "This test measures the resilience scale through ",
    sevenDimensions: "seven different dimensions",
    discoverYourLevel:
      "which help us understand and discover our strengths and areas for improvement.",
    reciliencyRemember: "",
    resiliency: "Resiliency",
    resiliencyDescription:
      " is defined as the ability to recover from setbacks and to continue moving forward, and even more so, being able to strengthen ourselves in the process.",
    resultInterpretation: "Results interpretation",
    interpretationDescription:
      "Depending on your results, you will find some suggestions for each category to help you boost your skills in that particular aspect, or to start developing them.",
    interpretationClasification:
      "Suggestions are divided by 3, depending on what needs to be developed in each dimension:",
    resolve: "1. Solve or Address: ",
    resolveDescription:
      "When you see this number in the dimension, it means that you should consider that you still have a lot to discover about yourself and that there are big growing opportunities for you.",
    foresee: "2. Foresee or Assure: ",
    foreseeDescription:
      "When you see this number in the dimension, it ́s very likely that you have several behaviors that benefit you, but you still have the opportunity to strengthen them and include others.",
    strengthen: "3. Strengthen or Expand: ",
    strengthenDescription:
      "When you see this number in the dimension, we may assume that you have big strengths in it, and this might be the right time to set new challenges, without leaving these behaviors that are good for you, behind.",
    yourSevenDimentions: "Your seven dimensions:",
    selfConfidence: "Self-confidence: ",
    selfConfidenceDescription:
      "Realistic and positive beliefs and attitudes about one ́s self and individual ability to influence the environment for the best.",
    adaptability: "Adaptability: ",
    adaptabilityDescription:
      "Ability to make one ́s approach to every situation more flexible in order to create new ideas and solutions.",
    determination: "Determination: ",
    determinationDescription:
      "Ability to develop and to maintain clear goals and values.",
    connection: "Connection: ",
    connectionDescription:
      "Ability to keep strong long term bonds with significant people in one ́s life. To develop and maintain positive relations.",
    positivity: "Positivity: ",
    positivityDescription:
      "Ability to create positive emotions by focusing on the good things of every experience. Gratitude is the key value of this dimension.",
    selfCare: "Self-Care: ",
    selfCareDescription:
      "Taking responsibility for personal wellbeing and acknowledging the importance of Self-care. Ability to develop physical and mental health strategies.",
    selfTrascendence: "Self-transcendence: ",
    selfTrascendenceDescription:
      "Connection with what ́s important and what gives meaning to our lives. This is a dynamic process that implies adaptability and trust in the environment, therefore creating coping mechanisms.",
    seeResults:
      "View your full report to understand your resilience levels in depth.",
    whatDoYouget: "What do you receive if you pay the results?",
    payment1: "When making your payment you will get a",
    pdf: " 18 pages PDF file ",
    payment2:
      "that contains all your resilience levels in depth, also a guide of recommendations to help you to improve on your daily.",
  },
  confirm: {
    youtubeText:
      "We recommend to visit our youtube channel where you can find content to support your learning",
    ready_1: "READY, YOU HAVE ALREADY TAKEN THE FIRST STEP TO DISCOVER",
    ready_2: "YOUR LEVEL OF RESILIENCE IN THE 7 DIMENSIONS!",
    leave: "Live",
    conscious: "conscious...",
    results: "Results",
    step1: "Click on the link and answer the TEST",
    step2:
      "Once the TEST is finished, you will get the report with the results of your TEST",
    step3: "Start your transformation process.",
    shop: "My Purchases",
    noTest: "There are no tests available.",
    goToShop: "Go to Shop",
  },
  account: {
    my: "My Account",
    fullname: "Full Name",
    mail: "Email",
    phone: "Phone-number",
    age: "Age",
    gender: "Gender",
    profession: "Profession",
    nationality: "Nationality",
    save: "Save",
    saveAndContinue: "Go To My Purchases",
    placeHolder_name: "Name(s)",
    placeHolder_lastName: "Last Name",
    placeHolder_familyName: "Mother's last name",
    placeHolder_email: "Enter your email",
    male: "male",
    female: "female",
    other: "other",
  },
  login: {
    welcome: "WELCOME",
    mail: "Email",
    password: "Password",
    forgot: "Have you forgotten your password?",
    login: "Log in",
    register: "Register Now",
    accept1: "I accept",
    accept2: "the terms and conditions",
    accept3: "of the service and I acknowledge having read your",
    accept4: "privacy policy",
    mailplaceholder: "Enter the purchase email",
    passwordplaceholder: "Enter your password",
    acceptTermsAndConditions: "Please accept the terms and conditions",
    passwordHint:
      "Minimum of 8 characters, must contain at least 1 digit, 1 Upper case, 1 lower case, and 1 special character",
    resetConfirmMsg:
      "An email will be send to reset the password to: {0}. \n Are you sure you want to continue?",
    resetPasswordEmailSent: "An email has been sent to reset the password.",
    invalidEmailOrPassword: "Invalid email or password",
    addEmailToResetPassword: "Please enter an email to reset the password.",
    errorOnResetPassword:
      "There was an error sending the email to reset password. Please check the email is correct.",
  },
  payment: {
    back: "< Go back",
    catpchaError: "Please complete the captcha",
    title: "My ",
    title2: "Start getting to know yourself",
    PaymentDetails: "Purchase details",
    PaymentDescription: "You will be charge $29 USD to buy a ResilienTest.",
    fullName: "Card Name",
    fullNamePlaceholder: "Card name",
    email: "Email",
    emailPlaceholder: "Enter your email",
    emailConfirm: "Confirm your email",
    emailConfirmPlaceHolder: "Confirm your email",
    emailNote: "* You will receive your results and the receipt at this email",
    numeroTarjeta: "Card Number",
    coupon: "Do you have a coupon?",
    couponPlaceHolder: "Enter the code here",
    couponValidate: "Validate",
    expirationMonth: "Expiration Month",
    expirationYear: "Expiration Year",
    cardExpYearPlaceholder: "YYYY",
    continueWithPayment: "Continue with payment",
    comprarTest: "Buy Test",
    emptyFieldsErrorMsg: "Please fill all fields.",
    successPaymentMsg:
      "Payment processed succesfully, now we will show your results in depth.",
    paymentErrorMsg:
      "Sorry, we couldn't process your payment, please try again.",
    currency: "USD",
    vatMessage: "plus VAT",
    resilientQuestion: "What do I get with the purchase of my ",
    resilientListTitle: "With your payment you will get this:",
    resilientList1:
      "A PDF file with 18 pages that contains your resilience results in depth.",
    resilientList2: "The description on each of your resilience dimension.",
    resilientList3:
      "A series of recommendations that you can use as a guide to improve your resilience levels.",
    applicantName: "Applicant's name",
    optional: "(Optional)",
    applicantNamePlaceHolder: "Name that will be on the Report",
    cardNumberPlaceHolder: "Enter your card number",
    emailDifferent: "email and confirmation did not match",
    assessmentNotAnswered:
      "Seems that you did not complete your test, now you will be redirected.",
    checkData: "Seems that you have incorrect information, please check!",
    cardError: "Your Card Number is not correct, please check!",
    error:
      "Ops! There was an unexpected error, please refresh the page and try again.",
    invalidCoupon: "Ops! Your coupon code is not valid, please check!",
    localCurrency: "* The payment will be on your local currency.",
    responsiveMessage:
      "For only $@price mxn you will get your full report with full details and advices for your development. Continue scrolling for more details…",
  },
  resetPassword: {
    my: "Change Password",
    mail: "Email",
    currentPassword: "Current Password",
    newPassword: "New Password",
    save: "Change password",
    errorFillAllFields: "Please fill all fields.",
    errorResetingPassword:
      "There was an error resetting the password. Check that the email is correct.",
    invalidTokenreset: "Invalid token for password reset",
    passwordResetSuccess: "Password has been reset successfully!",
  },
};
export default strings;
