import React from "react";
import Footer from "./Footer";
import Help from "./Help";

export const Layout = ({ children }) => {
  return (
    <div>
      {children}
      <Help />
      <Footer />
    </div>
  );
};
