import React, { useEffect, useState } from "react";
import banner1 from "../../assets/img/banner1.png";
import "./WelcomePage.scss";
import AxiosHttpClient from "../../Utils/AxiosHttpClient";
import generateEndpointUrl from "../../Utils/apiEndpointUrls";
import Loading from "../infrastructure/loadingAnimation";
import useTranslation from "../translations/translations";
import { useHistory } from "react-router-dom";
import { Nav } from "../common/Nav";

export default function WelcomeTest() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const translations = useTranslation();
  const [id, setId] = useState("");

  useEffect(() => {
    if (!id) {
        ensureAssessmentId();
    }
  });

    async function ensureAssessmentId() {
        const path = document.location.pathname;
        let testId = path.substring(path.lastIndexOf("/") + 1);
        if (!testId || testId.length < 3 || testId.toLowerCase().includes('resilientest')) {
            testId = await getAllUserAssessments();
        }

        setId(testId);
        setLoading(false);
    }

    async function getAllUserAssessments() {
        const url = `${generateEndpointUrl("UserAssessment")}/GetAllByUserId/es`;
        const response = await AxiosHttpClient.getData(url);

        if (response.status === 200 && response.data && response.data?.length > 0) {
            const pendingAssesmentsData = response.data.filter((d) => !d.completed);
            if (pendingAssesmentsData && pendingAssesmentsData.length > 0 && pendingAssesmentsData[0].id) {
                return pendingAssesmentsData[0].id;
            }
        }
        return "";
    }

  const gotoTest = (id) => {
    history.push(`/Assessments/${id}`);
  };

  return (
    <div id="main-content" className="welcome-page">
      <div className="">
        <div className="content">
          <Nav showTitle={true} dark={true} />
        </div>
      </div>

          {loading && <Loading />}

      <div className="sectionBanner">
        <div className="my-3 my-md-5">
          <h2 className="my-0 my-md-3 text-center text-uppercase">
            {translations.welcomeMessage}
          </h2>
          {/* TODO: get name */}
          {/* <h2 className="my-0 my-md-3 text-center text-uppercase">
                            PEDRO PÉREZ!
                        </h2> */}
          <div className="content-img-section">
            <img src={banner1} alt="menuIcon" />
          </div>
        </div>
      </div>

      <div>
        <div className="my-3 my-md-5">
          <h3 className="my-0 my-md-3 text-center">
            <strong className="manropeBold">{translations.instructions}</strong>
          </h3>
          <h3 className="my-0 my-md-3 pb-5 text-center">
            {translations.instructionsTest}
          </h3>
          <h3 className="text-center">
            <strong className="manropeBold p-5">
              56 {translations.questions}
            </strong>
            <strong className="manropeBold">{translations.timeAmount} </strong>
            25 {translations.minutes}
          </h3>
        </div>
      </div>

      <div className="d-flex justify-content-start text-center">
        <div className="contentBtn align-items-center pb-5">
          <span className="botonComenzar" onClick={() => gotoTest(id)}>{translations.beginTest}</span>
        </div>
      </div>
    </div>
  );
}
