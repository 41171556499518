import React from "react";
import ReactLoading from "react-loading";
import "./loadingAnimation.scss";

const LoadingAnimation = (props) => {
  return (
    <div className={`${props.classLoading ? props.classLoading : ""} loading`}>
      <ReactLoading
        className={`${props.className ? props.className : ""} spinner`}
        type={props.type || "spin"}
        height={props.height || "75px"}
        width={props.width || "75px"}
      />
    </div>
  );
};

export default LoadingAnimation;
