import React, { useState, useContext, useEffect } from "react";
import Cookies from "universal-cookie";
const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextProvider({ children }) {
  const [language, changeLanguage] = useState("es");
  useEffect(() => {
    let cookies = new Cookies();
    const lang = cookies.get("LANGUAGE");
    if (lang && lang !== undefined) changeLanguage(lang);
  }, []);

  useEffect(() => {
    let cookies = new Cookies();
    cookies.set("LANGUAGE", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
