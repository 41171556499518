import React, { useEffect, useState } from "react";
import "./Account.scss";
import Loading from "../components/infrastructure/loadingAnimation";
import browserHistory from "../components/infrastructure/browserHistory";
import MainBackgroundImage from "../assets/img/grid4.jpg";
import { Nav } from "./common/Nav";
import httpClient from "../Utils/AxiosHttpClient";
import useTranslation from "./translations/translations";
import { useLanguageContext } from "./context/LanguageContext";
import TagManager from "react-gtm-module";
import { getDataLayer } from "../Utils/dataLayer";
const tagManagerArgs = getDataLayer("resetPassword");
export const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const translations = useTranslation();
  const { changeLanguage } = useLanguageContext();
  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    const searchParams = props.location.search;
    let urlSearch = new URLSearchParams(searchParams);
    if (urlSearch.has("lang")) {
      let language = urlSearch.get("lang").toLowerCase();
      if (language !== "es" && language !== "en") {
        language = "es";
      }
      changeLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateUserPassword() {
    if (!email || !newPassword) {
      alert(translations.resetPassword.errorFillAllFields);
      return;
    }

    if (!isValidPassword(newPassword)) {
      alert(translations.login.passwordHint);
      return;
    }

    setLoading(true);
    try {
      const searchParams = props.location.search;
      if (!searchParams) {
        alert(translations.resetPassword.invalidTokenreset);
        return;
      }
      let urlSearch = new URLSearchParams(searchParams);
      if (!urlSearch.has("recoveryToken")) {
        alert(translations.resetPassword.invalidTokenreset);
        return;
      }
      const recoveryToken = urlSearch.get("recoveryToken").replaceAll(" ", "+");
      if (!recoveryToken || recoveryToken.length < 5) {
        alert(translations.resetPassword.invalidTokenreset);
        return;
      }
      const response = await httpClient.postData("api/Account/ChangePassword", {
        email,
        newPassword,
        recoveryToken,
      });
      if (!response || response.status !== 200) {
        alert(translations.resetPassword.errorResetingPassword);
        return;
      }

      alert(translations.resetPassword.passwordResetSuccess);
      browserHistory.push("/Login");
    } catch (e) {
      console.log("Error at updateUserPassword: ", e);
    } finally {
      setLoading(false);
    }
  }

  function isValidPassword(password) {
    if (!password || password.length < 8) {
      return false;
    }

    var lowerCaseLetters = /[a-z]/;
    if (!password.match(lowerCaseLetters)) {
      console.log("lower");
      return false;
    }

    var upperCaseLetters = /[A-Z]/;
    if (!password.match(upperCaseLetters)) {
      console.log("upper");
      return false;
    }

    var numbers = /[0-9]/;
    if (!password.match(numbers)) {
      console.log("digit");
      return false;
    }

    var specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/;
    if (!password.match(specialChars)) {
      console.log("char");
      return false;
    }

    return true;
  }

  return (
    <div
      id="main-content"
      className="container-fluid account"
      style={{
        background: `url(${MainBackgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      {loading && <Loading />}

      <div className="row">
        <div className="col-md-6 col-12 left-side">
          <div className="row">
            <Nav dark={true} className="d-md-none " />

            <div className="col-12">
              <h2 className="my-0 my-md-3 text-left text-uppercase">
                {translations.resetPassword.my}
              </h2>
            </div>

            <div className=" col-12 row">
              <div className="col-lg-8 col-12">
                <label className="manropeBold">
                  {translations.resetPassword.mail}
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className=" col-12 row">
              <div className="col-lg-8 col-12">
                <label className="manropeBold">
                  {translations.resetPassword.newPassword}
                </label>
                <input
                  className="form-control"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <span
                className="boton3 align-items-center justify-content-center"
                onClick={updateUserPassword}
              >
                {translations.resetPassword.save}
              </span>
            </div>
            <div className="text-center logo-bottom">
              <img src="/img/Logo con linea.png" alt="" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <Nav dark={true} className="d-none d-md-flex " />
        </div>
      </div>
    </div>
  );
};
