import React, { useEffect, useState } from "react";
import "./Account.scss";
import Loading from "./infrastructure/loadingAnimation";
import MainBackgroundImage from "../assets/img/grid4.jpg";
import { Nav } from "./common/Nav";
import httpClient from "../Utils/AxiosHttpClient";
import browserHistory from "./infrastructure/browserHistory";

export const CreateResilientest = () => {
    const [loading, setLoading] = useState(true);
    const [emailsToCreateResilientests, setEmailsToCreateResilientests] = useState('');

    async function validateToken() {
        const locationHref = window.location.href;
        const splittedHref = locationHref.split('/');
        const token = splittedHref[splittedHref.length - 1];
        const response = await httpClient.getData('api/Admin/ValidateTokenForResilientestsCreation/' + token);
        if (response.status !== 200) {
            let errorMsg = 'El Token en la url es inv�lido';
            if (response.status === 401) {
                errorMsg = 'Favor de iniciar sesion';
            }
            alert(errorMsg);
            browserHistory.push('/');
        }
    }

    async function createResilientests() {
        setLoading(true);
        try {
            const response = await httpClient.postData('api/Admin/CreateResilientests', { Emails: emailsToCreateResilientests });
            if (response && response.status === 200) {
                alert('Las Resilientests se guardaron exitosamente! \n Resilientests creadas: \n' + response.data);
            }
            else {
                alert('No se pudieron guardar las Resilientest, favor de intentar de nuevo.');
            }
        } catch (e) {
            console.log('Error at createResilientests', e);
        }        
        setLoading(false);
    }

    useEffect(() => {
        validateToken();
        setLoading(false);
    }, []);

    return (
        <div
            id="main-content"
            className="container-fluid account"
            style={{
                background: `url(${MainBackgroundImage})`,
                backgroundSize: "cover",
            }}
        >
            {loading && <Loading />}

            <div className="row">
                <div className="col-md-6 col-12 left-side">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="my-0 my-md-3 text-left text-uppercase" style={{ textAlign: 'center' }}>
                                Administrador
                            </h2>
                            <h2 className="my-0 my-md-3 text-left text-uppercase" style={{ textAlign: 'center' }}>
                                Crear Resilientests
                            </h2>
                        </div>
                        <div className=" col-12 row">
                            <div className=" col-12">
                                <label className="manropeBold">Ingrese los email(s) a los que se crearan las resilientests</label>

                                <input
                                    className="form-control"
                                    placeholder="Email(s) a crear resilientests"
                                    type="text"
                                    value={emailsToCreateResilientests}
                                    name="name"
                                    onChange={e => setEmailsToCreateResilientests(e.target.value)}
                                />
                            </div>                            
                        </div>
                        <div className="d-flex justify-content-center">
                            <span
                                className="boton3 align-items-center justify-content-center"
                                onClick={createResilientests}
                            >
                                Crear Resilientests
                            </span>
                        </div>
                        <div className="text-center logo-bottom">
                            <img src="/img/Logo con linea.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <Nav />
                </div>
            </div>
        </div>
    );
};
