import logo from "../../assets/img/HDSBajaRes.png";
import useTranslation from "../translations/translations";
const Footer = () => {
  const strings = useTranslation();

  return (
    <footer>
      <div className="text-footer w-100 d-flex manropeMedium align-items-center  color1">
        <div className="content d-flex justify-content-between">
          <p className="m-0">{strings.footer}</p>
          <div className="">
            {strings.by}{" "}
            <img src={logo} className="logo" alt="Resilientest logo" />{" "}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
