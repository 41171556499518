import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Steps from "./components/Steps";
import { Login } from "./components/Login";
import Pago from "./components/Pago";
import "./custom.scss";
import "./styles/styles.scss";
import WelcomeTest from "./components/Assessments/WelcomePage";
import { CreateResilientest } from "./components/CreateResilientest";
import { Layout } from "./components/common/Layout";
import Confirm from "./components/Confirm";
import { useAuth } from "./hooks/AuthProvider";
import Assessments from "./components/Assessments/Assessments";
import { Account } from "./components/Account";
import { ResetPassword } from "./components/ResetPassword";
import { PrivateRoute } from "./components/privateRoute";
import Loading from "react-loading";
import Results from "./components/Assessments/Results";
import WelcomePageAnonymous from "./components/Assessments/WelcomePageAnonymous";
import { clarity } from "react-microsoft-clarity";
import Report from "./components/Report";
import AdminPage from "./components/AdminPage";
import StatusReport from "./components/StatusReport";
export const App = () => {
  const auth = useAuth();
  const [initApp, seInitApp] = useState(false);

  useEffect(() => {
    auth.checkHealth().then((resp) => seInitApp(true));
  });
  useEffect(() => {
    clarity.init("gs7gve6yvh");
  }, []);
  if (!initApp) {
    return <Loading />;
  }
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/results" component={Results} />
        <Route path="/steps" component={Steps} />
        <Route path="/ResetPassword" component={ResetPassword} />
        <Route path="/welcome" component={WelcomePageAnonymous} />
        <Route path="/assessments" component={Assessments} />
        <Route path="/purchase" component={Pago} />
        {/*<PrivateRoute path="/assessments">
          <Assessments />
  </PrivateRoute>*/}
        <Route path="/login" component={Login} />
        <Route path="/registrarse" component={Login} />
        <PrivateRoute path="/account">
          <Account />
        </PrivateRoute>
        <PrivateRoute path="/admin">
          <AdminPage />
        </PrivateRoute>
        <PrivateRoute path="/report">
          <Report />
        </PrivateRoute>
        <PrivateRoute path="/statusReport">
          <StatusReport />
        </PrivateRoute>
        <PrivateRoute path="/Comprar-Resilientest">
          <Pago />
        </PrivateRoute>
        <PrivateRoute path="/confirm">
          <Confirm />
        </PrivateRoute>
        <PrivateRoute path="/Welcome-Resilientest">
          <WelcomeTest />
        </PrivateRoute>
        {/*ADMIN*/}
        <PrivateRoute path="/Admin/CreateResilientest">
          <CreateResilientest />
        </PrivateRoute>
      </Switch>
    </Layout>
  );
};
