import axios from "axios";
import authConfig from "../authorization/auth-config";

class AxiosHttpClient {
    instance = null;

    constructor() {
        this.instance = this.initializeInstance();
    }

    initializeInstance() {
        if (this.instance) {
            return this.instance;
        }

        let instance = axios.create();
        if (authConfig.authToken) {
            instance.defaults.headers.common["Authorization"] =
                "Bearer " + authConfig.authToken;
        }
        return instance;
    }

    refreshAuthToken() {
        if (authConfig.authToken) {
            let newInstance = axios.create();
            newInstance.defaults.headers.common["Authorization"] =
                "Bearer " + authConfig.authToken;
            this.instance = newInstance;
        }
    }

    async getData(url, config = {}) {
        try {
            const response = await this.instance.get(url, config);
            return { status: response.status, data: response.data };
        } catch (error) {
            return { status: (error.response || {}).status, data: error.data };
        }
    }

    async postData(url = "", param = {}, config = {}) {
        try {
            const response = await this.instance.post(url, param, config);
            return { status: response.status, data: response.data };
        } catch (error) {
            return { status: (error.response || {}).status, data: error.data };
        }
    }

    async putData(url = "", param = {}, config = {}) {
        try {
            const response = await this.instance.put(url, param, config);
            return { status: response.status, data: response.data };
        } catch (error) {
            return { status: (error.response || {}).status, data: error.data };
        }
    }

    async deleteData(url = "", param = {}, config = {}) {
        try {
            const response = await this.instance.delete(url, param, config);
            return { status: response.status, data: response.data };
        } catch (error) {
            return { status: (error.response || {}).status, data: error.data };
        }
    }

    async getFile(url, fileName) {
        try {
            this.instance({
                url: url,
                method: "GET",
                responseType: "blob", // important
            }).then((response) => {
                if (response?.data !== undefined) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                return { status: true };
            });
        } catch (error) {
            return { status: (error.response || {}).status, data: error.data };
        }
    }
}
// eslint-disable-next-line
export default new AxiosHttpClient();
