import React, { useState, useContext, createContext } from "react";
import generateEndpointUrl from "../Utils/apiEndpointUrls";
import httpClient from "../Utils/AxiosHttpClient";
import authConfig from "../authorization/auth-config";

const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleUser = (user) => {
    if (user) {
      setUser(user);
      setIsAuthenticated(true);
      setLoading(false);
      setIsAdmin(user.isAdmin);
      return user;
    } else {
      setUser(false);
      setIsAdmin(false);
      setIsAuthenticated(false);
      setLoading(false);
      return false;
    }
  };

  const checkHealth = async () => {
    if (isAuthenticated !== null) {
      return isAuthenticated;
    }
    if (!authConfig.authToken || authConfig.authToken.length < 5) {
      return false;
    }

    try {
      const response = await httpClient.getData(
        generateEndpointUrl("HealthCheckTokenStatus")
      );
      const isHealthCheckOk = response && response.status === 200;
      setIsAuthenticated(isHealthCheckOk);
      return isHealthCheckOk;
    } catch (e) {
      console.log("Error at IsAuthenticated: ", e);
    }
  };

  const checkAdmin = async () => {
    if (isAuthenticated === null) {
      return false;
    }
    if (!authConfig.authToken || authConfig.authToken.length < 5) {
      return false;
    }

    try {
      const response = await httpClient.getData(
        generateEndpointUrl("CheckAdmin")
      );
      const isHealthCheckOk = response && response.status === 200;
      return isHealthCheckOk;
    } catch (e) {
      console.log("Error at IsAuthenticated: ", e);
    }
  };

  const login = async ({ email, password }) => {
    const url = generateEndpointUrl("AccountLogin");
    const response = await httpClient.postData(url, { email, password });
    if (response && response.status === 200) {
      handleUser(response.data);
    }
    return response;
  };

  const register = async ({ email, password }, language) => {
    const url = generateEndpointUrl("AccountRegister");
    const response = await httpClient.postData(`${url}/${language}`, {
      email,
      password,
    });
    if (response && response.status === 200) {
      handleUser(response.data);
    }
    return response;
  };

  const registerByEmail = async (
    email,
    emailConfirm,
    assessmentId,
    language,
    applicantName
  ) => {
    const url = `${generateEndpointUrl("Account")}/RegisterByEmail`;
    const response = await httpClient.postData(`${url}/${language}`, {
      email,
      emailConfirm,
      assessmentId,
      applicantName,
    });
    if (response && response.status === 200) {
      setLoading(false);
    }
    return response;
  };

  const logout = () => {
    handleUser(false);
    authConfig.authToken = null;
  };

  return {
    isAuthenticated,
    checkHealth,
    user,
    loading,
    login,
    register,
    registerByEmail,
    logout,
    checkAdmin,
  };
}
