import React, { useEffect, useState } from "react";
import "./Results.scss";
import useTranslation from "../translations/translations";
import { useLanguageContext } from "../context/LanguageContext";
import { useHistory } from "react-router-dom";
import { Nav } from "../common/Nav";
import niveles from "../../assets/img/niveles.png";
import levels from "../../assets/img/levels.png";
import level1 from "../../assets/img/level1.png";
import level2 from "../../assets/img/level2.png";
import level3 from "../../assets/img/level3.png";
import btnResultsLg from "../../assets/img/btn-results-lg-en.png";
import btnResultsLgEs from "../../assets/img/btn-results-lg-es.png";
import generateEndpointUrl from "../../Utils/apiEndpointUrls";
import AxiosHttpClient from "../../Utils/AxiosHttpClient";

export default function Results() {
  const translations = useTranslation();
  const { language } = useLanguageContext();
  const history = useHistory();
  const [id, setId] = useState("");
  const [assessmentLevels, setLevels] = useState();
  useEffect(() => {
    const path = document.location.pathname;
    const testId = path.substring(path.lastIndexOf("/") + 1);
    if (testId.toLocaleLowerCase() === "results") {
      history.push("/welcome");
      return;
    }
    setId(testId);
    if (assessmentLevels === undefined) {
      const getLevels = async () => {
        const url = `${generateEndpointUrl("UserAssessment")}/levels/${testId}`;

        const response = await AxiosHttpClient.getData(url);
        if (response.status === 200) {
          return response.data;
        }

        return undefined;
      };

      getLevels().then((data) => {
        if (data === undefined) history.push("/welcome");
        setLevels(data);
      });
    }
    // eslint-disable-next-line
  }, []);

  const gotoPayment = () => {
    window.location.href = `/purchase/${id}`;
  };

  const getLevelImage = (name) => {
    if (assessmentLevels === undefined) return level1;

    const level = assessmentLevels[name];
    switch (level) {
      case "1":
        return level1;
      case "2":
        return level2;
      case "3":
        return level3;
      default:
        return level1;
    }
  };
  return (
    <div id="main-content" className="results-page">
      <div className="">
        <div className="content">
          <Nav showTitle={false} dark={true} />
        </div>
      </div>
      <section className="sectionBanner">
        <div className="section3">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center logo-container">
              <h2>
                {translations.results.header}
                <span className="manropeLight">resilien</span>
                <span className="poppinsbold">TEST</span>
                <span className="manropeLight text-bottom">&reg;</span>!
              </h2>
            </div>
          </div>
          <div className="content-img-section contImg1"></div>
        </div>
      </section>
      <section className="container">
        <div className="row ">
          <div className="col-12">
            <p>
              {translations.results.resultsDescription}
              <strong>{translations.results.sevenDimensions}</strong>{" "}
              {translations.results.discoverYourLevel}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            {language === "en" && (
              <img src={levels} className="levels" alt="menuIcon" />
            )}
            {language === "es" && (
              <img src={niveles} className="levels" alt="menuIcon" />
            )}
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p>
              {translations.results.reciliencyRemember}{" "}
              <strong>{translations.results.resiliency}</strong>{" "}
              {translations.results.resiliencyDescription}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p>
              <strong>{translations.results.resultInterpretation}</strong>
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p>
              {translations.results.interpretationDescription}
              <br />
              {translations.results.interpretationClasification}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p>
              <strong className="orange">
                {translations.results.resolve}{" "}
              </strong>
              {translations.results.resolveDescription}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p>
              <strong className="orange">
                {translations.results.foresee}{" "}
              </strong>
              {translations.results.foreseeDescription}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p>
              <strong className="orange">
                {translations.results.strengthen}{" "}
              </strong>
              {translations.results.strengthenDescription}
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <p>
              <strong>{translations.results.yourSevenDimentions}</strong>
            </p>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <p className="my-0">
              <strong className="orange">1.</strong>{" "}
              <strong>{translations.results.selfConfidence} </strong>
              {translations.results.selfConfidenceDescription}
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <img
              src={getLevelImage("Confianza en sí mismo")}
              className="level"
              alt="menuIcon"
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <p className="my-0">
              <strong className="orange">2.</strong>{" "}
              <strong>{translations.results.adaptability}</strong>
              {translations.results.adaptabilityDescription}
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <img
              src={getLevelImage("Adaptabilidad")}
              className="level"
              alt="menuIcon"
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <p className="my-0">
              <strong className="orange">3.</strong>{" "}
              <strong>{translations.results.determination} </strong>
              {translations.results.determinationDescription}
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <img
              src={getLevelImage("Determinación")}
              className="level"
              alt="menuIcon"
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <p className="my-0">
              <strong className="orange">4.</strong>{" "}
              <strong>{translations.results.connection} </strong>
              {translations.results.connectionDescription}
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <img
              src={getLevelImage("Conexión")}
              className="level"
              alt="menuIcon"
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <p className="my-0">
              <strong className="orange">5.</strong>{" "}
              <strong>{translations.results.positivity} </strong>
              {translations.results.positivityDescription}
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <img
              src={getLevelImage("Positividad")}
              className="level"
              alt="menuIcon"
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <p className="my-0">
              <strong className="orange">6.</strong>{" "}
              <strong>{translations.results.selfCare}</strong>
              {translations.results.selfCareDescription}
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <img
              src={getLevelImage("Autocuidado")}
              className="level"
              alt="menuIcon"
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col-lg-9 col-md-12 col-sm-12 d-flex align-items-center">
            <p className="my-0">
              <strong className="orange">7.</strong>{" "}
              <strong>{translations.results.selfTrascendence} </strong>
              {translations.results.selfTrascendenceDescription}
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <img
              src={getLevelImage("Autotrascendencia")}
              className="level"
              alt="menuIcon"
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <p className="">{translations.results.seeResults}</p>
          </div>
        </div>
        <div className="row mt-4 px-2 pb-5">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <img
              src={language === "es" ? btnResultsLgEs : btnResultsLg}
              className="btn-results large"
              alt="menuIcon"
              onClick={() => gotoPayment(id)}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
