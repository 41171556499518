import React from "react";
import Hamburger from "../menu/Hamburger";
import { useLanguageContext } from "../../components/context/LanguageContext";
import homeDark from "../../assets/img/home-dark.svg";
import home from "../../assets/img/Vector.svg";
import { useHistory } from "react-router-dom";

export const Nav = ({ className = "", showTitle, dark, hideHome = false }) => {
  const { changeLanguage } = useLanguageContext();
  const history = useHistory();

  const handleChangeLenguage = (value) => {
    changeLanguage(value);
  };
  const gotoHome = () => {
    history.push("/");
  };
  return (
    <nav
      className={"navbar  menu-responsive " + className + (dark ? "dark" : "")}
    >
      <Hamburger dark={dark} />
      {showTitle && (
        <div className="title-container logo-container d-flex justify-content-center align-items-center">
          <h3>
            <span className="manropeLight">resilien</span>
            <span className="poppinsbold">TEST</span>
            <span className="manropeLight text-bottom">&reg;</span>
          </h3>
        </div>
      )}
      <ul id="idioma" className=" ml-auto mt-2 mt-lg-0 mb-md-0">
        <li className="nav-item" onClick={() => handleChangeLenguage("en")}>
          english
        </li>
        <li className="nav-item" onClick={() => handleChangeLenguage("es")}>
          español
        </li>
        {hideHome === false && (
          <li className="icono-home" onClick={gotoHome}>
            <img className="img-fluid" src={dark ? homeDark : home} alt="" />
          </li>
        )}
      </ul>
    </nav>
  );
};
