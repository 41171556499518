import React, { useEffect, useState } from "react";
import "./Account.scss";
import Loading from "../components/infrastructure/loadingAnimation";
import browserHistory from "../components/infrastructure/browserHistory";
import MainBackgroundImage from "../assets/img/grid4.jpg";
import { Nav } from "./common/Nav";
import httpClient from "../Utils/AxiosHttpClient";
import generateEndpointUrl from "../Utils/apiEndpointUrls";
import useTranslation from "./translations/translations";
import TagManager from "react-gtm-module";
import { getDataLayer } from "../Utils/dataLayer";
const tagManagerArgs = getDataLayer("account");
export const Account = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const url = `${generateEndpointUrl("PersonalInfo")}`;
  TagManager.dataLayer(tagManagerArgs);
  const translations = useTranslation();

  async function getUserInfo() {
    let response = await httpClient.getData(url);
    if (response.status === 200 || response.data) {
      setUser(response.data);
      return;
    }
    if (response.status === 401) {
      alert(translations.pls_login);
      browserHistory.push("/");
      return;
    }
  }
  useEffect(() => {
    getUserInfo().then((r) => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <div
      id="main-content"
      className="container-fluid account"
      style={{
        background: `url(${MainBackgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      {loading && <Loading />}

      <div className="row account-content">
        <div className="col-md-6 col-12 left-side">
          <div className="row">
            <Nav dark={true} className="d-lg-none d-xl-none" />

            <div className="col-12">
              <h2 className="my-0 my-md-3 text-left text-uppercase">
                {translations.account.my}
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                <label className="manropeBold label">
                  {translations.account.fullname}
                </label>
                <input
                  className="form-control"
                  disabled
                  value={!user.name ? "" : user.name}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                <label className="manropeBold">
                  {translations.account.mail}
                </label>
                <input
                  className="form-control"
                  value={!user.email ? "" : user.email}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                <label className="manropeBold">
                  {translations.account.age}
                </label>
                <input
                  className="form-control"
                  value={!user.ageRange ? "" : user.ageRange}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                <label className="manropeBold">
                  {translations.account.gender}{" "}
                </label>
                <input
                  className="form-control"
                  value={!user.gender ? "" : user.gender}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                <label className="manropeBold">
                  {translations.account.profession}
                </label>
                <input
                  className="form-control"
                  value={!user.profession ? "" : user.profession}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                <label className="manropeBold">
                  {translations.account.nationality}
                </label>

                <input
                  className="form-control"
                  value={!user.nationality ? "" : user.nationality}
                  disabled
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="d-flex justify-content-center">
                <span
                  className="boton3 align-items-center justify-content-center"
                  onClick={() => browserHistory.push("/Confirm/")}
                >
                  {translations.account.saveAndContinue}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-center">
                <img src="/img/Logo con linea.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12">
          <Nav dark={true} className="d-none d-md-flex " />
        </div>
      </div>
    </div>
  );
};
