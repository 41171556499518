import React from "react";
import { useHistory } from "react-router-dom";

import step1 from "../assets/img/Paso1.png";
import step2 from "../assets/img/Paso2.png";
import step3 from "../assets/img/Paso3.png";
import step4 from "../assets/img/Paso4.png";
import step5 from "../assets/img/Paso5.png";
import step6 from "../assets/img/Paso6.png";
import vector from "../assets/img/Vector.svg";
import es from "../assets/img/es.png";
import us from "../assets/img/us.png";
import download from "../assets/img/download.png";
import logo from "../assets/img/logo.svg";
import useTranslation from "./translations/translations";
import { useLanguageContext } from "./context/LanguageContext";

const Steps = () => {
  const history = useHistory();
  const strings = useTranslation();

  const { changeLanguage, language } = useLanguageContext();

  const handleChangeLenguage = (value) => {
    changeLanguage(value);
  };
  const gotoHome = () => {
    history.push("/");
  };

  return (
    <div id="main-content">
      <header>
        <div className=" bg-f0 ">
          <div className="content">
            <nav className="navbar  menu-responsiv navbar-expand-lg justify-content-between ">
              <div className="navbar-brand logo">
                <span className="">
                  <img className="img-fluid" src={logo} alt="" />
                </span>
              </div>

              <ul id="idioma" className=" ml-auto mt-2 mt-lg-0 mb-md-0">
                <li
                  className="nav-item mr-3 mr-md-4 d-flex align-items-center"
                  onClick={gotoHome}
                >
                  <div className="icono-home">
                    <img className="img-fluid" src={vector} alt="" />
                  </div>
                  <span className="nav-link link f-18 poppinsbold font-weight-bold color3 text-decoration-none linkHome">
                    {strings.steps_start}
                  </span>{" "}
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link"
                    onClick={() => handleChangeLenguage("en")}
                  >
                    <img className="icono" src={us} alt="" />
                  </span>{" "}
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link"
                    onClick={() => handleChangeLenguage("es")}
                  >
                    <img className="icono" src={es} alt="" />
                  </span>{" "}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <main className="content-main-inter">
        <div className="content">
          <section id="interSectText">
            <div>
              <h2 className="text-center">{strings.steps_question}</h2>
            </div>

            <div className=" justify-content-between align-items-center text-center">
              <div className="d-flex align-items-center contTextInt h-100">
                <div>
                  <p>{strings.steps_desc}</p>

                  <p>{strings.steps_desc_2}</p>

                  <p>{strings.steps_desc_3}</p>
                </div>
              </div>

              <div className="w-100 flex">
                <div id="contImgPasos" className="">
                  <img id="imgPasos" className="img-fluid" src={""} alt="" />
                </div>
              </div>
            </div>
          </section>

          <section id="interSectPasos">
            <h2>{strings.steps_see_results}</h2>

            <div className="row">
              <div className="col-12 col-md mb-3 mb-md-0">
                <div>
                  <div className="text-center imgPasosCont">
                    <img className="img-fluid" src={step1} alt="" />
                  </div>
                  <p className="text-center">{strings.steps_step1}</p>
                </div>
              </div>
              <div className="col-12 col-md mb-3 mb-md-0">
                <div>
                  <div className="text-center imgPasosCont">
                    <img className="img-fluid" src={step2} alt="" />
                  </div>
                  <p className="text-center">{strings.steps_step2}</p>
                </div>
              </div>
              <div className="col-12 col-md mb-3 mb-md-0">
                <div>
                  <div className="text-center imgPasosCont">
                    <img className="img-fluid" src={step3} alt="" />
                  </div>
                  <p className="text-center">{strings.steps_step3}</p>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-md mt-3 my-md-0">
                <div>
                  <div className="text-center imgPasosCont">
                    <img className="img-fluid" src={step4} alt="" />
                  </div>
                  <p className="text-center">{strings.steps_step4}</p>
                </div>
              </div>
              <div className="col-12 col-md mt-3 my-md-0">
                <div>
                  <div className="text-center imgPasosCont">
                    <img className="img-fluid" src={step5} alt="" />
                  </div>
                  <p className="text-center">{strings.steps_step5}</p>
                </div>
              </div>
              <div className="col-12 col-md mt-3 my-md-0">
                <div>
                  <div className="text-center imgPasosCont">
                    <img className="img-fluid" src={step6} alt="" />
                  </div>
                  <p className="text-center">{strings.steps_step6}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="">
            <h2 id="verInt" className="text-center">
              {strings.steps_see_delivery}
            </h2>
            <div id="listBtnInter" className="d-flex justify-content-center">
              <a
                href={
                  "https://resilientest.com/demo/" +
                  (language === "es" ? "spanish" : "english") +
                  ".pdf"
                }
              >
                <button id="btnDemo" className="btnAutLine">
                  <img src={download} alt="" />
                  {strings.steps_see_sample}
                </button>
              </a>
            </div>

            <div id="btnProssInter" className="d-flex justify-content-center">
              <a
                href="/Comprar-Resilientest"
                className="btnPay prosBtn d-block"
              >
                {strings.steps_proceed}
              </a>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};
export default Steps;
