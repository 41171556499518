import React, { useState, useEffect } from "react";
import Question from "./Question";
import "./Assessments.scss";
import generateEndpointUrl from "../../Utils/apiEndpointUrls";
import AxiosHttpClient from "../../Utils/AxiosHttpClient";
import Loading from "../../components/infrastructure/loadingAnimation";
import WarningModal from "../common/modal/WarningModal";
import SuccessModal from "../common/modal/SuccessModal";
import useTranslation from "../translations/translations";
import browserHistory from "../infrastructure/browserHistory";
import { useLanguageContext } from "../context/LanguageContext";
import TagManager from "react-gtm-module";
import { getDataLayer } from "../../Utils/dataLayer";
const tagManagerArgs = getDataLayer("home");
export default function Assessments(props) {
  TagManager.dataLayer(tagManagerArgs);
  const translations = useTranslation();
  const currentLanguage = useLanguageContext();
  const [loading, setLoading] = useState(true);
  const [showSuccess, setSuccess] = useState(false);
  const [isLastPage, setLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(-1);
  const [itemsPerPage, setItemsperPage] = useState(10);
  const [showWarning, setShowWarning] = useState(false);
  const [questions, setQuestions] = useState();
  const [selectedQuestions, setSelectedQuestions] = useState();
  const [count, setCount] = useState(0);
  const [isPaid, setIsPad] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [id, setId] = useState("");
  const [informationLoaded, setInformationLoaded] = useState(false);
  const [userInformation, setUserInformation] = useState({
    ageRange: "",
    gender: "",
    profession: "",
    nationality: "",
  });
  const [haveDemographics, setHaveDemographics] = useState(false);
  const [ages] = useState(translations.demographics.ages);
  const [genders] = useState(translations.demographics.genders);
  const [nationality] = useState(translations.demographics.nationality);
  useEffect(() => {
    if (!informationLoaded) {
      getInfoFromUser().then((info) => {
        if (info === undefined) {
          setUserInformation({
            ageRange: ages[0],
            gender: genders[0],
            nationality: nationality[0],
            profession: "",
          });
        } else {
          setUserInformation(info);
        }
        setInformationLoaded(true);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!questions) {
      getAssessment().then((data) => {
        if (data?.completed) {
          if (data?.isPaid) browserHistory.push("/Confirm");
          browserHistory.push(`/Results/${data.id}`);
        }
        setCurrentPage(data?.lastPage);
        setItemsperPage(data?.itemsPerPage > 0 ? data?.itemsPerPage : 10);
        setQuestions(data?.questions);
        setIsPad(data?.isPaid);
      });
    } else if (firstLoad) {
      setFirstLoad(false);
      selectQuestionsToShow();
    }
    if (selectedQuestions !== undefined) {
      checkForLastPage();
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, selectedQuestions]);

  async function getAssessment() {
    const path = document.location.pathname;
    const testId = path.substring(path.lastIndexOf("/") + 1);
    setId(testId);
    const url = `${generateEndpointUrl("UserAssessment")}/${testId}`;
    const response = await AxiosHttpClient.getData(url);
    if (response.status === 200 || response.data || response.data?.length > 0) {
      return response.data;
    }

    browserHistory.push("/");
  }

  async function getInfoFromUser() {
    const url = `${generateEndpointUrl("User")}/PersonalInformationByIp`;
    const response = await AxiosHttpClient.getData(url);
    if (response.status === 200 || response.data || response.data?.length > 0) {
      const haveInformation =
        response.data.ageRange !== "" &&
        response.data.gender !== "" &&
        response.data.profession !== "" &&
        response.data.profession !== "";

      setHaveDemographics(haveInformation);

      return response.data;
    }
    return undefined;
  }
  async function saveDemographics() {
    const url = `${generateEndpointUrl("User")}/PersonalInformationByIp`;
    const response = await AxiosHttpClient.postData(url, userInformation);
    if (response.status === 200) {
      return true;
    }

    return false;
  }
  function selectQuestionsToShow(direction, save = false) {
    if (!questions) return;

    if (save) SaveProgress();

    let current = currentPage;
    direction = direction === undefined ? "next" : direction;
    if (selectedQuestions && direction === "next" && !areQuestionsAswered())
      return;

    if (direction === "previous") current -= 1;
    else current += 1;
    var totalPages = Math.ceil(questions.length / itemsPerPage - 1);
    current = Math.min(current, totalPages);
    const start = current * itemsPerPage;
    let end = Math.min(start + itemsPerPage, questions.length);

    setCurrentPage(current);
    setSelectedQuestions(questions.slice(start, end));
    toTop();
  }

  function areQuestionsAswered() {
    let valid = haveDemographics;
    const unresponded = selectedQuestions.find((e) => e.questionAnswer === 0);
    let areDemographicsValid = !isLastPage;
    if (isLastPage) {
      areDemographicsValid =
        userInformation.ageRange !== ages[0] &&
        userInformation.gender !== genders[0] &&
        userInformation.profession !== "" &&
        userInformation.nationality !== nationality[0];
      if (areDemographicsValid && !haveDemographics) {
        saveDemographics().then((val) => {
          valid = val;
        });
      }
    }

    if (unresponded || !areDemographicsValid) {
      setShowWarning(true);
      return false;
    }
    valid = unresponded === undefined;
    return valid;
  }

  function checkForLastPage() {
    const lastPage =
      selectedQuestions[selectedQuestions.length - 1].index ===
      questions[questions.length - 1].index;
    setLastPage(lastPage);
  }

  async function SaveProgress() {
    const url = `${generateEndpointUrl("UserAssessment")}`;
    const body = {
      AssessmentId: id,
      questions: questions,
      lastPage: currentPage,
      language: currentLanguage.language,
    };
    const response = await AxiosHttpClient.putData(url, body);
    if (response.status === 200 || response.data || response.data.length > 0) {
      return response.data;
    }
  }

  function updateQuestionValue(index, value) {
    const question = questions.find((q) => q.index === index);
    question.questionAnswer = value;
    setCount(count + 1);
  }

  function endTest() {
    if (!areQuestionsAswered()) return;
    SaveProgress().then((e) => {
      window.location.href = `/results/${id}`;
      setSuccess(true);
    });
  }

  function closeWarning() {
    setShowWarning(false);
  }
  const updateUser = (e) => {
    const { name, value } = e.target;
    setUserInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  function closeSuccess() {
    setSuccess(false);
    if (isPaid) browserHistory.push("/Confirm");
    browserHistory.push("/Results");
  }
  function toTop() {
    document.querySelector("#assessmentContainer").scrollTo(0, 0);
  }
  function buttons() {
    return (
      <div className={"row " + count}>
        <div className="col-6 pl-0">
          <div className="d-flex justify-content-start">
            <button
              className="btn btn-primary"
              disabled={!currentPage > 0}
              onClick={() => selectQuestionsToShow("previous")}
            >
              {"< " + translations.assessmentButtons.previous}
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end">
            {!isLastPage && (
              <button
                className="btn btn-primary"
                onClick={() => selectQuestionsToShow("next", true)}
              >
                {translations.assessmentButtons.next + " >"}
              </button>
            )}
            {isLastPage && (
              <button className="btn btn-end" onClick={endTest}>
                {translations.assessmentButtons.endTest + " >"}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="assessment" id="assessmentContainer">
        {loading && <Loading />}
        <WarningModal show={showWarning} closeFnx={closeWarning}></WarningModal>
        <SuccessModal show={showSuccess} closeFnx={closeSuccess} />
        <div className="container my-5 assessment-container">
          {buttons()}
          <div className="d-flex justify-content-center mb-2">
            <hr></hr>
          </div>
          {selectedQuestions?.map((question) => {
            return (
              <div key={question.index}>
                <Question
                  question={question}
                  number={question.index}
                  name={"question" + question.index}
                  value={question.questionAnswer}
                  onChangeFxn={updateQuestionValue}
                  questionType={question.questionType}
                ></Question>
              </div>
            );
          })}
          {isLastPage && !haveDemographics && (
            <>
              <div className="row pt-3">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <p>{translations.demographics.description}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <div className="row">
                    <div className="col-12">
                      <label className="input-label">
                        {translations.account.age}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <select
                        className="form-control"
                        name="ageRange"
                        onChange={updateUser}
                      >
                        {ages.map((g, key) => (
                          <option key={key + "g"} value={g}>
                            {g.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <div className="row">
                    <div className="col-12">
                      <label className="input-label">
                        {translations.account.gender}
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <select
                        className="form-control"
                        name="gender"
                        onChange={updateUser}
                      >
                        {genders.map((g, key) => (
                          <option key={key + "g"} value={g}>
                            {g.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <div className="row">
                    <div className="col-12">
                      <label className="input-label">
                        {translations.account.profession}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <input
                        className="form-control"
                        name="profession"
                        onChange={updateUser}
                        placeholder={
                          translations.demographics.professionPlaceHolder
                        }
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <div className="row">
                    <div className="col-12">
                      <label className="input-label">
                        {translations.account.nationality}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <select
                        className="form-control"
                        name="nationality"
                        onChange={updateUser}
                      >
                        {nationality.map((g, key) => (
                          <option key={key + "g"} value={g}>
                            {g.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="d-flex justify-content-center mt-2">
            <hr></hr>
          </div>
          {buttons()}
        </div>
      </div>
    </>
  );
}
