const getDataLayer = (pageName) => {
  return {
    dataLayer: {
      userProject: "ResilienTest",
      page: pageName,
    },
    dataLayerName: pageName + "DataLayer",
  };
};
export { getDataLayer };
