import React, { useEffect, useState } from "react";
import "./Assessments.scss";
import useTranslation from "../translations/translations";
import { useLanguageContext } from "../context/LanguageContext";

export default function Question(props) {
  const translations = useTranslation();
  const { language } = useLanguageContext();
  const [answers, setAnswers] = useState([1, 2, 3, 4]);
  useEffect(() => {
    if (props.questionType === 1) setAnswers([4, 3, 2, 1]);
  }, [props.questionType]);

  return (
    <div className="mb-4 mt-4 ml-3 mr-3 question-container">
      <div className="row">
        <div className="question-index">
          <strong>{props.number}</strong>
        </div>
        <div className="col-auto d-flex align-items-center question-text">
          <p className="mb-0">{props.question.texts[language.toUpperCase()]}</p>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-md-3 d-flex align-items-center">
          <input
            id=""
            type="radio"
            name={props.name}
            checked={props.question.questionAnswer === answers[0]}
            value="1"
            className="radio-style big incolor blue"
            required=""
            onChange={() => props.onChangeFxn(props.number, answers[0])}
          />
          <label className="radio-label mensage1">
            {translations.completelyDisagree}
          </label>
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <input
            type="radio"
            name={props.name}
            checked={props.question.questionAnswer === answers[1]}
            value="2"
            className="incolor blue small radio-style"
            onChange={() => props.onChangeFxn(props.number, answers[1])}
            required=""
          />
          <label className="radio-label">{translations.disagree}</label>
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <input
            type="radio"
            checked={props.question.questionAnswer === answers[2]}
            name={props.name}
            value="3"
            className="incolor green small radio-style"
            onChange={() => props.onChangeFxn(props.number, answers[2])}
            required=""
          />
          <label className="radio-label green">{translations.agree}</label>
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <input
            type="radio"
            checked={props.question.questionAnswer === answers[3]}
            name={props.name}
            value="4"
            className="incolor green radio-style big"
            onChange={() => props.onChangeFxn(props.number, answers[3])}
            required=""
          />
          <label className="radio-label green">
            {translations.completelyAgree}
          </label>
        </div>
      </div>
    </div>
  );
}
