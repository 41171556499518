import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import history from "./components/infrastructure/browserHistory";
import { App } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import LanguageContextProvider from "./components/context/LanguageContext";
import { AuthProvider } from "./hooks/AuthProvider";
import TagManager from "react-gtm-module";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const tagManagerArgs = {
  gtmId: "GTM-NWM6PKD",
};
TagManager.initialize(tagManagerArgs);
ReactDOM.render(
  <AuthProvider>
    <Router basename={baseUrl} history={history}>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </Router>
  </AuthProvider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
