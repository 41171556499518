import Cookies from "universal-cookie";
import axios from "axios";

class AuthConfig {
  requestInterceptorHandle = null;
  responseInterceptorHandle = null;
  _cookieOptions = { path: "/" };
  _sessionValid = false;
  TOKEN_KEY = "RESILIENTEST_TOKEN";
  USER_ID = "USER_ID";
  AUTHORIZATION = "Authorization";

  /**
   * Reads the auth token from the session cookie
   * @returns {string} authToken: The auth token of the current session
   */
  get authToken() {
    let cookies = new Cookies();
    let token = cookies.get(this.TOKEN_KEY) || null;
    return token;
  }

  set authToken(token) {
    let cookies = new Cookies();
    cookies.set(this.TOKEN_KEY, token, this._cookieOptions);
  }

  set authToken(token) {
    let cookies = new Cookies();
    cookies.set(this.TOKEN_KEY, token, this._cookieOptions);
  }

  get sessionAvailable() {
    return this._sessionValid;
  }

  set sessionAvailable(valid) {
    this._sessionValid = valid;
  }

  /**
   * Redirects user in event of unauthorized response
   */
  _redirectUnauthorized() {}

  /**
   * Registes response interceptor
   */
  _initAuthResponseInterceptor() {
    this.responseInterceptorHandle = axios.interceptors.response.use(
      (response) => {
        if (response) {
        }
        return response;
      },
      (error) => {
        if (error) {
          //@TODO: error need to give us status
          // this.sessionAvailable = false;
          //@TODO: use authservice logout function
        }
      }
    );
  }

  async initialize() {
    this._initAuthResponseInterceptor();
  }
}
// eslint-disable-next-line
export default new AuthConfig();
