import React, { useState, useEffect } from "react";
import { Nav } from "./common/Nav";
import PDF from "../assets/icons/pdf.png";
import AxiosHttpClient from "../Utils/AxiosHttpClient";
import generateEndpointUrl from "../Utils/apiEndpointUrls";
import "./Confirm.scss";
import Loading from "./infrastructure/loadingAnimation";
import useTranslation, { useGetLenguage } from "./translations/translations";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import { getDataLayer } from "../Utils/dataLayer";
const tagManagerArgs = getDataLayer("Confirm");
export default function Confirm() {
  const [loading, setLoading] = useState(true);
  const [pendingAssessments, setPendingAssessments] = useState([]);
  const [completedAssessments, setCompletedAssessments] = useState([]);
  const history = useHistory();
  const translations = useTranslation();
  const lenguage = useGetLenguage();
  TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    try {
      setLoading(true);
      getAllUserAssessments().then((data) => {
        if (!data || data.length <= 0) {
          return;
        }
        const pendingAssesmentsData = data.filter((d) => !d.completed);
        const completedAssesmentsData = data.filter(
          (d) => d.completed === true
        );
        setPendingAssessments(pendingAssesmentsData);
        setCompletedAssessments(completedAssesmentsData);
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function getResultsUserAssessment(userAssesmentId, fileName) {
    setLoading(true);
    const url = `${generateEndpointUrl(
      "UserAssessment"
    )}/GetResults/${lenguage}/1/${userAssesmentId}`;
    await AxiosHttpClient.getFile(url, `${fileName}.pdf`);

    await delay(5000);

    setLoading(false);
  }

  async function getAllUserAssessments() {
    const url = `${generateEndpointUrl("UserAssessment")}/GetAllByUserId/es`;
    const response = await AxiosHttpClient.getData(url);
    if (response.status === 200 || response.data || response.data?.length > 0) {
      return response.data;
    }
  }

  const gotoTest = (id) => {
    setLoading(true);
    history.push(`/Welcome-Resilientest/${id}`);
  };

  const gotoResults = (id) => {
    setLoading(true);
    history.push(`/Results/${id}`);
  };

  return (
    <div id="main-content" className="confirm">
      <div className="">
        <div className="content">
          <Nav showTitle={true} dark={true} />
        </div>
      </div>

      {loading && <Loading />}
      <section className="section  mt-md-3">
        <div className="content d-md-flex justify-content-center mt-1 mb-4">
          <div className="franja m-r-8"></div>
        </div>
        <div className="text-center py-4 d-none d-md-block">
          <div className="m-r-8">
            <h3>
              <strong className="manropeBold">
                {translations.confirm.ready_1}
              </strong>
            </h3>
            <h3>
              <strong className="manropeBold">
                {translations.confirm.ready_2}
              </strong>
            </h3>
          </div>
        </div>
        <div>
          <div className="contImg1">
            <div className="d-flex justify-content-md-end align-items-center h-100 contentText-img">
              <div className="content-text-img  tarjeta1 d-flex justify-content-md-end align-items-center h-100 px-4">
                <div className="w-100 w-100 text-right white">
                  <p>
                    {" "}
                    <strong>{translations.confirm.leave}</strong>{" "}
                    {translations.confirm.conscious}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-left mini-container py-4">
          {pendingAssessments.length > 0 && (
            <div>
              <div className="manropeBold ">{translations.confirm.shop}</div>
              <br />
              {pendingAssessments.map((pa) => (
                <div className="mb-4" key={pa.id}>
                  Test:{" "}
                  <span className="hover" onClick={() => gotoTest(pa.id)}>
                    {pa.userEmail}
                  </span>
                </div>
              ))}
            </div>
          )}
          {pendingAssessments.length > 0 && (
            <div className="d-md-flex justify-content-center py-4">
              <div className="franja full"></div>
            </div>
          )}
          <div>
            <p>{translations.confirm.results}:</p>
          </div>
          {completedAssessments.length > 0 &&
            completedAssessments.map((ca) => (
              <div key={ca.id}>
                {ca.isPaid && (
                  <div
                    className="resultsDiv hover"
                    onClick={() =>
                      getResultsUserAssessment(ca.id, ca.userEmail)
                    }
                  >
                    <img className="icon" src={PDF} alt="resilientest result" />
                    <label> {ca.userEmail}</label>
                  </div>
                )}
                {!ca.isPaid && (
                  <span className="hover" onClick={() => gotoResults(ca.id)}>
                    {ca.userEmail}
                  </span>
                )}
              </div>
            ))}
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center manropeBold">
              <strong>{translations.confirm.youtubeText}</strong>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center mt-4">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/lMm5y1rNeo0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
