const strings = {
  /** Common **/
  resilien: "resilien",
  test: "TEST",
  now: "ahora",
  begin: "haz el ",
  begin_2: "hazlo ",
  in: "En ",
  here: "aquí",
  footer: "©2022 Resilientest. Todos los derechos reservados.",
  by: "By",
  pls_login: "Favor de iniciar sesion",
  success: " ¡Datos guardados exitosamente!",
  fail: "Hubo un error al guardar, favor de intentar de nuevo.",
  whatsappHelpMsg: "Hola, necesito ayuda con resilientest",
  /** End Common **/

  /** Hamburguer **/
  h_myaccount: "Mi Cuenta",
  h_myshopping: "Mis Compras",
  h_logout: "Cerrar Sesión",
  h_login: "Iniciar Sesión",
  h_register: "Registrarse",
  /** End Hamburguer **/

  /** Home Page **/
  home_infoHelp:
    "El diagnóstico que te ayudará a descubrir tu nivel de Resiliencia.",
  home_quote:
    "No son los más fuertes de la especie los que sobreviven, ni los más inteligentes. Sobreviven los más flexibles y adaptables al cambio.",
  home_author: "Charles Darwin",
  home_question: "¿qué es la resiliencia?",
  home_quote2: "Y cuando te conviertas en diamante, sabrás por que la vida ",
  home_quote2_2: "tuvo que presionarte...",
  home_text:
    "La <span class='f-18 manropeBold'>resiliencia</span> es la capacidad de recuperarse de los contratiempos y poder seguir adelante, y más aún, poder fortalecerte en el proceso.",
  home_text_2:
    "Teniendo claridad de cómo piensas, sientes y accionas ante los retos que se te presentan en la vida (personal, familiar, profesional, etc) tienes la oportunidad de resolver, prever y fortalecer.",
  home_text_3:
    "la escala de resiliencia se mide al abordar <strong> siete dimensiones </strong> que ayudan a entender dónde están tus fortalezas y debilidades respecto a tu mecanismo de reacción.",
  home_square_title: "Confianza en uno mismo",
  home_square_text:
    "Son las creencias y actitudes realistas y positivas sobre ti mismo y la capacidad individual que tienes para influir en el entorno para mejorar.",
  home_square_title_2: "Adaptabilidad",
  home_square_text_2:
    "Es la habilidad para flexibilizar tu enfoque y generar nuevas ideas y soluciones.",
  home_square_title_3: "Determinación",
  home_square_text_3:
    "Es la capacidad que tienes de construir y mantener propósitos y valores claros.",
  home_square_title_4: "Conexión",
  home_square_text_4:
    "Es mantener lazos fuertes y de largo plazo con personas importantes en tu vida. Construir y mantener relaciones positivas.",
  home_square_title_5: "Positividad",
  home_square_text_5:
    "Es la facilidad de generar emociones positivas a través de enfocarte en lo bueno de cada experiencia. Gratitud es la clave de esta dimensión.",
  home_square_title_6: "Autocuidado",
  home_square_text_6:
    "Es la capacidad de tomar responsabilidad por el bienestar personal y la importancia del autocuidado. Capacidad de establecer estrategias de salud física y mental.",
  home_square_title_7: "Autotrascendencia",
  home_square_text_7:
    "Es la conexión con lo trascendente que da significado a nuestra existencia. Es un proceso dinámico que implica la adaptación y confianza en el entorno, generando mecanismos de afrontamiento.",
  home_why: "¿PARA QUÉ NECESITAS ESTO…",
  home_why_2: "Y POR QUÉ LO NECESITAS AHORA?",
  home_generic: "Al realizar tu",
  home_generic_2: "aprenderás a:",
  home_banner_title: "DESCUBRIR",
  home_banner_text: "el poder que tienes dentro de ti para ser mejor cada día.",
  home_banner_title_2: "CONOCER",
  home_banner_text_2:
    "las actitudes y comportamientos que te ayudan a mejorar y los que no.",
  home_banner_text_2_2:
    "¡Comienza a trazar la ruta del cambio de acuerdo a los resultados de tu test!",
  home_banner_title_3: "TRANSFORMAR",
  home_banner_text_3:
    "las adversidades en oportunidades y alcanzar la mejor versión de ti.",
  home_banner_text_3_2:
    "¡Contarás con una serie de consejos que te permitirán mejorar tu nivel en cada una de las 7 dimensiones!",
  home_question_2: "¿No sabes cómo empezar?",
  home_doIt: "HAZLO AHORA",
  home_click: "Da clic",
  home_answer: "para contestar el",
  home_question_3: "¿QUÉ RECIBES SI HACES EL TEST?",
  home_desc:
    "Un reporte con la interpretación de tu nivel de resiliencia en cada una de las 7 dimensiones",
  home_see_report: "Para ver el reporte completo, ¡",

  /** End Home Page **/
  /** steps Page **/
  steps_start: "Inicio",
  steps_question: "¿Qué es ResilienTEST?",
  steps_desc:
    "ResilienTEST es una autoevaluación que te ayudará a identificar el nivel de resiliencia que tienes y cómo lo presentas en diferentes ángulos o dimensiones de tu vida.",
  steps_desc_2:
    "Además de obtener tus resultados, también identificarás tus fortalezas y áreas de oportunidad en cada una de las dimensiones y obtendrás recursos, tips y actividades para entenderte mejor y desarrollar tu resiliencia.",
  steps_desc_3:
    "La aplicación consta de una serie de preguntas de opción múltiple en las que tendrás que elegir la mejor respuesta que defina tu comportamiento. Esta aplicación dura alrededor de 30 minutos.",
  steps_see_results: "Si quieres obtener tus resultados:",
  steps_step1:
    "Registra tus datos: Tu correo electrónico es muy importante para la recepción de tus resultados.",
  steps_step2: "Procede al pago.",
  steps_step3:
    "Espera en tu correo electrónico el link de acceso a la aplicación.",
  steps_step4: "Resuelve el cuestionario.",
  steps_step5: "Descarga tus resultados y recíbelo en tu correo.",
  steps_step6: "Comunícate con nosotros para cualquier duda.",
  steps_see_delivery: "Ve aquí el entregable:",
  steps_see_sample: "Sample Report",
  steps_proceed: "Proceder a la compra",
  /** End Account Page **/
  completelyDisagree: "Completamente en desacuerdo",
  disagree: "En desacuerdo",
  agree: "De acuerdo",
  completelyAgree: "Completamente de acuerdo",
  welcomeMessage: "¡BIENVENIDO A ",
  instructions: "Instrucciones",
  instructionsTest:
    "Responde las siguientes preguntas de acuerdo a la percepción de ti mismo. No hay respuestas correctas o incorrectas.",
  questions: "preguntas",
  timeAmount: "Tiempo aproximado: ",
  minutes: "minutos",
  help: "Ayuda",
  beginTest: "Comenzar el TEST",
  assessmentButtons: {
    previous: "Anterior",
    next: "Siguiente",
    endTest: "Finalizar Test",
  },
  successModal: {
    success: "¡Haz completado tu resilienTEST!",
    completionMessage:
      "Consulta tus resultados en la página principal al iniciar sesión.",
  },
  warningModal: {
    questionNeedsAnswer: "Aún no contestas todas las preguntas,",
    continueMessage: "¡hazlo para continuar!",
  },
  demographics: {
    ages: [
      "Seleccione una opción",
      "Menos de 18 años",
      "Entre 18 y 25",
      "Entre 26 y 35",
      "Entre 36 y 45",
      "Entre 46 y 55",
      "Entre 56 y 65",
      "Más de 65 años",
    ],
    genders: ["Seleccione una opción", "Masculino", "Femenino", "Otro"],
    nationality: [
      "Seleccione una opción",
      "Afganistán",
      "Albania",
      "Alemania",
      "Andorra",
      "Angola",
      "Antigua y Barbuda",
      "Arabia Saudita",
      "Argelia",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaiyán",
      "Bahamas",
      "Bangladés",
      "Barbados",
      "Baréin",
      "Bélgica",
      "Belice",
      "Benín",
      "Bielorrusia",
      "Birmania",
      "Bolivia",
      "Bosnia y Herzegovina",
      "Botsuana",
      "Brasil",
      "Brunéi",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Bután",
      "Cabo Verde",
      "Camboya",
      "Camerún",
      "Canadá",
      "Catar",
      "Chad",
      "Chile",
      "China",
      "Chipre",
      "Ciudad del Vaticano",
      "Colombia",
      "Comoras",
      "Corea del Norte",
      "Corea del Sur",
      "Costa de Marfil",
      "Costa Rica",
      "Croacia",
      "Cuba",
      "Dinamarca",
      "Dominica",
      "Ecuador",
      "Egipto",
      "El Salvador",
      "Emiratos Árabes Unidos",
      "Eritrea",
      "Eslovaquia",
      "Eslovenia",
      "España",
      "Estados Unidos",
      "Estonia",
      "Etiopía",
      "Filipinas",
      "Finlandia",
      "Fiyi",
      "Francia",
      "Gabón",
      "Gambia",
      "Georgia",
      "Ghana",
      "Granada",
      "Grecia",
      "Guatemala",
      "Guyana",
      "Guinea",
      "Guinea ecuatorial",
      "Guinea-Bisáu",
      "Haití",
      "Honduras",
      "Hungría",
      "India",
      "Indonesia",
      "Irak",
      "Irán",
      "Irlanda",
      "Islandia",
      "Islas Marshall",
      "Islas Salomón",
      "Israel",
      "Italia",
      "Jamaica",
      "Japón",
      "Jordania",
      "Kazajistán",
      "Kenia",
      "Kirguistán",
      "Kiribati",
      "Kuwait",
      "Laos",
      "Lesoto",
      "Letonia",
      "Líbano",
      "Liberia",
      "Libia",
      "Liechtenstein",
      "Lituania",
      "Luxemburgo",
      "Macedonia del Norte",
      "Madagascar",
      "Malasia",
      "Malaui",
      "Maldivas",
      "Malí",
      "Malta",
      "Marruecos",
      "Mauricio",
      "Mauritania",
      "México",
      "Micronesia",
      "Moldavia",
      "Mónaco",
      "Mongolia",
      "Montenegro",
      "Mozambique",
      "Namibia",
      "Nauru",
      "Nepal",
      "Nicaragua",
      "Níger",
      "Nigeria",
      "Noruega",
      "Nueva Zelanda",
      "Omán",
      "Países Bajos",
      "Pakistán",
      "Palaos",
      "Panamá",
      "Papúa Nueva Guinea",
      "Paraguay",
      "Perú",
      "Polonia",
      "Portugal",
      "Reino Unido",
      "República Centroafricana",
      "República Checa",
      "República del Congo",
      "República Democrática del Congo",
      "República Dominicana",
      "Ruanda",
      "Rumanía",
      "Rusia",
      "Samoa",
      "San Cristóbal y Nieves",
      "San Marino",
      "San Vicente y las Granadinas",
      "Santa Lucía",
      "Santo Tomé y Príncipe",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leona",
      "Singapur",
      "Siria",
      "Somalia",
      "Sri Lanka",
      "Suazilandia",
      "Sudáfrica",
      "Sudán",
      "Sudán del Sur",
      "Suecia",
      "Suiza",
      "Surinam",
      "Tailandia",
      "Tanzania",
      "Tayikistán",
      "Timor Oriental",
      "Togo",
      "Tonga",
      "Trinidad y Tobago",
      "Túnez",
      "Turkmenistán",
      "Turquía",
      "Tuvalu",
      "Ucrania",
      "Uganda",
      "Uruguay",
      "Uzbekistán",
      "Vanuatu",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Yibuti",
      "Zambia",
      "Zimbabues",
    ],
    professionPlaceHolder: "¿A qué te dedicas?",
    description:
      "Para finalizar, completa estos últimos datos para generar tus resultados",
  },
  results: {
    header: "¡Haz completado tu ",
    resultsDescription: "Aquí podrás encontrar los resultados en las ",
    sevenDimensions: "7 dimensiones de resiliencia.",
    discoverYourLevel: "Descubre tu nivel en cada una de ellas.",
    reciliencyRemember: "Recuerda que la ",
    resiliency: "resiliencia",
    resiliencyDescription:
      " es la capacidad de recuperarse de los contratiempos, seguir adelante y más aún, fortalecernos en el proceso.",
    resultInterpretation: "Interpretación de los resultados",
    interpretationDescription:
      "Dependiendo de tus resultados, encontrarás para cada dimensión algunas sugerencias para potencializar tus habilidades en este rubro o bien para empezar a desarrollarlas.",
    interpretationClasification:
      "Las sugerencias se clasifican en 3 dependiendo de las necesidades de desarrollo en cada dimensión:",
    resolve: "1. Resolver o abordar: ",
    resolveDescription:
      "Cuando aparezca este número en la dimensión, deberás considerar que tienes aún mucho por conocer de ti mismo y grandes oportunidades de crecimiento.",
    foresee: "2. Preveer o Asegurar: ",
    foreseeDescription:
      "Cuando aparezca este número en la dimensión, lo más probable es que tengas varios comportamientos que te favorecen, pero aún tienes la oportunidad de fortalecerlos e incorporar otros.",
    strengthen: "3. Fortalecer o Ampliar: ",
    strengthenDescription:
      "Cuando aparezca este número en la dimensión, podemos suponer que tienes amplias fortalezas en la misma, y puede ser el momento de plantearse nuevos retos sin dejar atrás los comportamientos que te favorecen.",
    yourSevenDimentions: "Tus 7 dimensiones:",
    selfConfidence: "Confianza en sí mismo: ",
    selfConfidenceDescription:
      "Creencias y actitudes realistas y positivas sobre uno mismo. Capacidad individual para influir en el entorno para mejorar.",
    adaptability: "Adaptabilidad: ",
    adaptabilityDescription:
      "Capacidad para flexibilizar nuestro enfoque y generar nuevas ideas y soluciones.",
    determination: "Determinación: ",
    determinationDescription:
      "Capacidad de construir y mantener propósitos y valores claros.",
    connection: "Conexión: ",
    connectionDescription:
      "Capacidad de mantener lazos fuertes y de largo plazo con personas importantes en nuestra vida. Construir y mantener relaciones positivas.",
    positivity: "Positividad: ",
    positivityDescription:
      "Capacidad de generar emociones positivas a través de enfocarse en lo bueno de cada experiencia. Gratitud es la clave de esta dimensión.",
    selfCare: "Autocuidado: ",
    selfCareDescription:
      "Tomar responsabilidad por el bienestar personal y la importancia del autocuidado. Capacidad de establecer estrategias de salud física y mental.",
    selfTrascendence: "Autotrascendencia: ",
    selfTrascendenceDescription:
      "Conexión con lo trascendente que da significado a nuestra existencia. Es un proceso dinámico que implica la adaptación y confianza en el entorno, generando mecanismos de afrontamiento.",
    seeResults:
      "Visualiza tu reporte completo para entender tus niveles de resiliencia a profundidad.",
    whatDoYouget: "¿Qué obtengo con mis resultados?",
    payment1: "Al realizar tu pago podrás obtener un archivo en formato",
    pdf: " PDF con 18 páginas ",
    payment2:
      "que contiene todos los resultados de tus niveles, la descripción y una serie de recomendaciones que te servirán como guía para ayudarte mejorar en tu día a día.",
  },
  confirm: {
    youtubeText:
      "Te recomendamos visitar nuestro canal de youtube donde encontrarás contenido para apoyar tu aprendizaje",
    ready_1: "¡LISTO, YA DISTE EL PRIMER PASO PARA DESCUBRIR",
    ready_2: "TU NIVEL DE RESILIENCIA EN LAS 7 DIMENSIONES!",
    leave: "Vive",
    conscious: "consciente...",
    results: "Resultados",
    step1: "Da clic en el link y responde el TEST",
    step2:
      "Una vez finalizado el TEST, obtendrás el reporte con los resultados de tu TEST",
    step3: "Inicia tu proceso de transformación.",
    shop: "Mis Compras",
    noTest: "No hay tests disponibles.",
    goToShop: "Ir a comprar test",
  },
  account: {
    my: "Mi Cuenta",
    fullname: "Nombre Completo",
    mail: "Correo",
    phone: "Telefono",
    age: "Edad",
    gender: "Género",
    profession: "Ocupación",
    nationality: "Nacionalidad",
    save: "Guardar",
    saveAndContinue: "Ir a Mis Compras",
    placeHolder_name: "Nombres(s)",
    placeHolder_lastName: "Apellido Paterno",
    placeHolder_familyName: "Apellido Materno",
    placeHolder_email: "Ingresa tu correo electrónico",
    male: "masculino",
    female: "femenino",
    other: "otro",
  },
  login: {
    welcome: "BIENVENIDO",
    mail: "Correo electrónico",
    password: "Contraseña",
    forgot: "¿Has olvidado tu contraseña?",
    login: "Iniciar sesión",
    register: "Registrarse",
    accept1: "Acepto los",
    accept2: "términos y condiciones",
    accept3: "del servicio y reconozco haber leído su",
    accept4: "política de privacidad",
    mailplaceholder: "Ingresa tu correo usado en la compra",
    passwordplaceholder: "Ingresa tu contraseña",
    acceptTermsAndConditions: "Favor de aceptar los términos y condiciones",
    passwordHint:
      "Mínimo de 8 caracteres, debe contener por lo menos 1 dígito, 1 letra mayúscula, 1 letra minúscula y 1 caracter especial",
    resetConfirmMsg:
      "Se enviará un email para resetear el password a: {0}. \n ¿Está seguro que quiere continuar?",
    resetPasswordEmailSent:
      "Se ha enviado un email para resetear el password exitosamente.",
    invalidEmailOrPassword: "Contraseña o email inválido",
    addEmailToResetPassword:
      "Favor de ingresar un email para resetear el password.",
    errorOnResetPassword:
      "Hubo un error al mandar el mail para resetear el password. Cheque que el email sea correcto.",
  },
  payment: {
    back: "< Regresar",
    title: "Mi ",
    catpchaError: "Por favor completa el captcha",
    title2: "Comienza a conocerte a ti mismo",
    PaymentDetails: "Detalle de compra",
    PaymentDescription:
      "Se hara un cargo de $580 pesos para la compra de tu ResilienTest.",
    fullName: "Nombre como aparece en la tarjeta",
    fullNamePlaceholder: "Nombre del titular de la tarjeta",
    email: "Correo electrónico",
    emailPlaceholder: "Ingresa tu correo electrónico",
    emailConfirm: "Confirma tu correo electrónico",
    emailConfirmPlaceHolder: "Confirma tu correo electrónico",
    emailNote:
      "* En el correo electrónico ingresado recibirás tus resultados y el recibo de la compra.",
    numeroTarjeta: "Número de tarjeta",
    coupon: "¿Tienes un cupón?",
    couponPlaceHolder: "Ingrésalo aquí",
    couponValidate: "Validar",
    expirationMonth: "Mes de Expiración",
    expirationYear: "Año de Expiración",
    cardExpYearPlaceholder: "AAAA",
    continueWithPayment: "Continuar con el pago",
    comprarTest: "Comprar test",
    emptyFieldsErrorMsg: "Favor de llenar todos los campos",
    successPaymentMsg:
      "Tu pago se procesó correctamente, a continuación te presentamos tus resultados a profundidad.",
    paymentErrorMsg:
      "Lo sentimos no se pudo procesar su pago, favor de intentar de nuevo.",
    currency: "MXN",
    vatMessage: "más IVA",
    resilientQuestion: "¿Qué obtengo al adquirir los resultados de mi ",
    resilientListTitle: "Al realizar tu pago obtendrás:",
    resilientList1:
      "Un archivo en formato PDF con 18 páginas que contiene tus resultados a profundidad.",
    resilientList2:
      "La descripción de cada una de las dimensiones de tu resiliencia.",
    resilientList3:
      "Una serie de recomendaciones que te servirán como guía para ayudarte a mejorar tus niveles de resiliencia.",
    applicantName: "Nombre del aplicante",
    optional: "(Opcional)",
    applicantNamePlaceHolder: "Nombre que aparecerá en el reporte",
    cardNumberPlaceHolder: "Ingresa el número de tu tarjeta",
    emailDifferent: "Los correos ingresados no coinciden",
    assessmentNotAnswered:
      "Parece que no haz respondido tu resilienTest, a continuación serás redirigido",
    checkData: "Parece que tienes información incorrecta, por favor revísala.",
    cardError: "Tu número de tarjeta es incorrecto, por favor revísalo.",
    error:
      "Ups! Parece que hubo un error, por favor refresca la página e intenta nuevamente.",
    invalidCoupon: "Ups! Parece que tu cupón no es válido, por favor revísalo.",
    localCurrency: "* El pago se verá reflejado en tu moneda local.",
    responsiveMessage:
      "Obtén tu reporte completo, con toda la explicación y consejos de desarrollo por sólo $@price pesos. Ve abajo para más detalles…",
  },
  resetPassword: {
    my: "Cambiar contraseña",
    mail: "Correo",
    currentPassword: "Password actual",
    newPassword: "Password nuevo",
    save: "Cambiar contraseña",
    errorFillAllFields: "Favor de llenar todos los campos",
    errorResetingPassword:
      "Hubo un error al resetear el password. Cheque que el email sea correcto.",
    invalidTokenreset: "No existe un token valido para resetear el password.",
    passwordResetSuccess: "Se ha reseteado el password exitosamente.",
  },
};
export default strings;
