import { useImagesTranslation } from "../translations/translations";
import useTranslation from "../translations/translations";

const Help = () => {
  const images = useImagesTranslation();
  const translations = useTranslation();
  const whatsappHref = 'https://api.whatsapp.com/send?phone=525541172544&text=' + translations.whatsappHelpMsg

  return (
    <div className="help d-flex justify-content-around">
      <a
        href={whatsappHref}
        target="_blank"
        rel="noreferrer"
      >
        <img src={images.help} alt="help" />
      </a>
    </div>
  );
};
export default Help;
