import React from "react";
import { useHistory } from "react-router-dom";
import Arrows from "../assets/icons/Flechas.png";

import { Nav } from "./common/Nav";
import useTranslation from "./translations/translations";
import TagManager from "react-gtm-module";
import { getDataLayer } from "../Utils/dataLayer";
const tagManagerArgs = getDataLayer("home");
const Home = () => {
  const history = useHistory();
  const strings = useTranslation();
  TagManager.dataLayer(tagManagerArgs);
  const gotoAssessment = () => {
    history.push("/welcome");
  };
  return (
    <div id="main-content">
      <header id="headerHome">
        <div className="">
          <div className="content">
            <Nav className={"navbar-dark"} hideHome={true} />
          </div>
        </div>
        <div className="">
          <div className="contMessage1 ">
            <div className="content d-flex justify-content-center d-lg-block ">
              <div className="message1">
                <div className="h-100">
                  <div className="d-flex w-100 h-100  justify-content-md-start align-items-center">
                    <div id="text-message1" className="row">
                      <div className="col-12 d-flex align-items-center justify-content-center logo-container">
                        <h1>
                          <span className="manropeLight">resilien</span>
                          <span className="poppinsbold">TEST</span>
                        </h1>
                        <p className="manropeLight rights pt-4">&reg;</p>
                      </div>
                      <p className="color1">{strings.home_infoHelp}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="d-block d-md-flex mt-lg-5 flex-wrap justify-content-center justify-content-lg-between">
              <div className="contentBtn d-flex justify-content-center justify-content-md-start align-items-center"></div>

              <div className="my-3 mt-md-4 mt-lg-0">
                <div className="content-cita">
                  <p className="text-right color1 ">{strings.home_quote}</p>
                  <p className="mt-3 mt-md-4 color1 f-18 manropeBold text-right ">
                    {strings.home_author}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="d-block d-md-flex mt-lg-5 flex-wrap justify-content-center justify-content-lg-between">
              <div className="contentBtn d-flex justify-content-center justify-content-md-start align-items-center">
                <span className="boton1" onClick={gotoAssessment}>
                  <div className="d-flex justify-content-between align-items-center contTextBtn1">
                    <span className="btnArrows">
                      <img className="img-fluid" src={Arrows} alt="" />
                    </span>{" "}
                    <span className="text-btn1">
                      <span>
                        {" "}
                        {strings.begin}
                        <span className="color2 manropeSemiBold link">
                          {strings.test}
                        </span>{" "}
                        {strings.now}
                      </span>
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div id="vert-dark" className="bg-dark"></div>
        </div>
      </header>
      <main>
        <section className="section1">
          <div className="my-3 my-md-5">
            <h2 className="my-0 my-md-3 text-center text-uppercase">
              {strings.home_question}
            </h2>
            <div className="content-img-section1">
              <div className="content">
                <div className="content-text-img">
                  <p className="color1 text-img-section1 italicPop">
                    {strings.home_quote2}
                    <span className="color2"> {strings.home_quote2_2}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="text-center content-text-section1">
              <p dangerouslySetInnerHTML={{ __html: strings.home_text }}></p>

              <p>{strings.home_text_2}</p>
            </div>

            <div className="d-flex mb-md-3 justify-content-center">
              <div className="contFranjbt">
                <div className="color2 my-5 franja"></div>
                <div className="mx-auto">
                  <div className="contentBtn w-100 d-flex justify-content-center  align-items-center">
                    <span className="boton1" onClick={gotoAssessment}>
                      <div className="d-flex justify-content-between align-items-center contTextBtn1">
                        <span className="btnArrows">
                          <img className="img-fluid" src={Arrows} alt="" />
                        </span>{" "}
                        <span className="text-btn1">
                          <span>
                            {" "}
                            {strings.begin}
                            <span className="color2 manropeSemiBold link">
                              {strings.test}
                            </span>{" "}
                            {strings.now}
                          </span>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section2 color1">
          <div className="content-grid">
            <div className="row">
              <div className="col-md-8 grid col-12 pl-0">
                <div className="px-md-5 bg-f0 h-100">
                  <div className="d-flex p-5  px-md-5 h-100 justify-content-center align-items-center">
                    <p>
                      {strings.in}
                      <strong onClick={gotoAssessment} className="link">
                        {strings.resilien}
                        {strings.test}
                      </strong>{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: strings.home_text_3,
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 pr-0">
                <div className="grid grid1">
                  <div className="text-hover p-5 p-md-0 ">
                    <div className="d-flex h-100 px-md-5 justify-content-center align-items-center">
                      <p className="off">
                        <strong> {strings.home_square_title}</strong>
                      </p>

                      <p className="hover">
                        <strong>{strings.home_square_title}</strong> <br />
                        {strings.home_square_text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-12 pl-0">
                <div className="grid grid2">
                  <div className="text-hover p-5 p-md-0 ">
                    <div className="d-flex h-100 px-md-5 justify-content-center align-items-center">
                      <p className="off">
                        <strong>{strings.home_square_title_2}</strong>
                      </p>
                      <p className="hover">
                        <strong>{strings.home_square_title_2}</strong> <br />
                        {strings.home_square_text_2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="grid grid3">
                  <div className="text-hover p-5 p-md-0 ">
                    <div className="d-flex h-100 px-md-5 justify-content-center align-items-center">
                      <p className="off">
                        <strong>{strings.home_square_title_3}</strong>
                      </p>
                      <p className="hover">
                        <strong>{strings.home_square_title_3}</strong> <br />
                        {strings.home_square_text_3}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 pr-0">
                <div className="grid grid4">
                  <div className="text-hover p-5 p-md-0 ">
                    <div className="d-flex h-100 px-md-5 justify-content-center align-items-center">
                      <p className="off">
                        <strong>{strings.home_square_title_4}</strong>
                      </p>
                      <p className="hover">
                        <strong>{strings.home_square_title_4}</strong> <br />
                        {strings.home_square_text_4}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row last">
              <div className="col-md-4 col-12 pl-0">
                <div className="grid grid5">
                  <div className="text-hover p-5 p-md-0 ">
                    <div className="d-flex h-100 px-md-5 justify-content-center align-items-center">
                      <p className="off">
                        <strong>{strings.home_square_title_5}</strong>
                      </p>
                      <p className="hover">
                        <strong>{strings.home_square_title_5}</strong> <br />
                        {strings.home_square_text_5}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="grid grid6">
                  <div className="text-hover p-5 p-md-0 ">
                    <div className="d-flex h-100 px-md-5 justify-content-center align-items-center">
                      <p className="off">
                        <strong>{strings.home_square_title_6}</strong>
                      </p>
                      <p className="hover">
                        <strong>{strings.home_square_title_6}</strong> <br />
                        {strings.home_square_text_6}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 pr-0">
                <div className="grid grid7">
                  <div className="text-hover p-5 p-md-0 ">
                    <div className="d-flex h-100 px-md-5 justify-content-center align-items-center">
                      <p className="off">
                        <strong>{strings.home_square_title_7}</strong>
                      </p>
                      <p className="hover">
                        <strong>{strings.home_square_title_7}</strong> <br />
                        {strings.home_square_text_7}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-text-section2 justify-content-center align-items-center d-flex manropeBold-span">
            <p>
              {" "}
              {strings.begin}
              <span className="color2 link" onClick={gotoAssessment}>
                {strings.test}
              </span>{" "}
              {strings.now}
            </p>
          </div>
        </section>

        <section className="section3  mt-md-3">
          <div className="d-md-block d-md-flex justify-content-center py-4">
            <div className="franja "></div>
          </div>
          <div className="text-center py-4 d-md-block">
            <h3 className="">
              {strings.home_why}{" "}
              <strong className="manropeBold">{strings.home_why_2}</strong>{" "}
            </h3>
            <h3>
              {strings.home_generic}{" "}
              <span onClick={gotoAssessment} className="link">
                {" "}
                {strings.resilien}
                {strings.test}{" "}
              </span>{" "}
              {strings.home_generic_2}
            </h3>
          </div>

          <div>
            <div className="content-text-img-mobil d-sm-none  d-flex justify-content-md-end align-items-center h-100 px-4 py-5">
              <div className="text-left">
                <h2>{strings.home_banner_title}</h2>
                <p>{strings.home_banner_text}</p>
              </div>
            </div>

            <div className="contImg1">
              <div className="d-flex justify-content-md-end align-items-center h-100 contentText-img">
                <div className="content-text-img tarjeta tarjeta1 d-flex justify-content-md-end align-items-center h-100 px-4">
                  <div className="triangulo-left"></div>
                  <div className="w-100">
                    <h2>{strings.home_banner_title}</h2>

                    <p>{strings.home_banner_text}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="content-text-img d-sm-none d-block text-right h-100 px-4 py-5">
              <h2>{strings.home_banner_title_2}</h2>
              <p>{strings.home_banner_text_2} </p>
              <p>{strings.home_banner_text_2_2}</p>
            </div>

            <div className="contImg2">
              <div className="d-flex justify-content-md-start align-items-center h-100 contentText-img">
                <div className="content-text-img tarjeta tarjeta2 d-flex justify-content-md-end align-items-center h-100 px-4">
                  <div className="text-right w-100">
                    <h2>{strings.home_banner_title_2}</h2>
                    <p>{strings.home_banner_text_2} </p>
                    <p>{strings.home_banner_text_2_2}</p>
                  </div>
                  <div className="triangulo-right"></div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="content-text-img d-block d-sm-none px-4">
              <div className="my-5">
                <h2>{strings.home_banner_title_3}</h2>
                <p>{strings.home_banner_text_3} </p>
                <p>{strings.home_banner_text_3_2}</p>
              </div>
            </div>
            <div className="contImg3">
              <div className="d-flex justify-content-md-end align-items-center h-100 contentText-img">
                <div className="content-text-img tarjeta tarjeta3 d-flex justify-content-md-end align-items-center h-100 px-4">
                  <div className="triangulo-left"></div>
                  <div className="w-100">
                    <h2>{strings.home_banner_title_3}</h2>
                    <p>{strings.home_banner_text_3} </p>
                    <p>{strings.home_banner_text_3_2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="contImg4">
              <div className="color1 text-center d-flex justify-content-center align-items-center h-100">
                <div onClick={gotoAssessment}>
                  <h2 className="manrope">{strings.home_question_2}</h2>
                  <h2 className="manropeBold"> {strings.home_doIt}</h2>
                  <h2 className="manrope">
                    {strings.home_click}{" "}
                    <span className="link" onClick={gotoAssessment}>
                      {" "}
                      {strings.here}
                    </span>{" "}
                    {strings.home_answer}{" "}
                    <span className="color2 link" onClick={gotoAssessment}>
                      {strings.test}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="section4">
          <div className="d-flex justify-content-center py-4">
            <div className="franja "></div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Home;
