import React, { useEffect, useState } from "react";
import banner1 from "../../assets/img/banner1.png";
import "./WelcomePage.scss";
import AxiosHttpClient from "../../Utils/AxiosHttpClient";
import generateEndpointUrl from "../../Utils/apiEndpointUrls";
import Loading from "../infrastructure/loadingAnimation";
import useTranslation from "../translations/translations";
import { useHistory } from "react-router-dom";
import { Nav } from "../common/Nav";

export default function WelcomePageAnonymous() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const translations = useTranslation();
  const [id, setId] = useState("");

  useEffect(() => {
    if (!id) {
      ensureAssessmentId();
    }
  });

  async function ensureAssessmentId() {
    const testId = await createAnonymous();
    setId(testId);
    setLoading(false);
  }

  async function createAnonymous() {
    const url = `${generateEndpointUrl("UserAssessment")}/anonymous`;
    const response = await AxiosHttpClient.postData(url);

    if (response.status === 200) {
      return response.data;
    }
    return "";
  }

  const gotoTest = (id) => {
    history.push(`/Assessments/${id}`);
  };

  return (
    <div id="main-content" className="welcome-page">
      <div className="">
        <div className="content">
          <Nav showTitle={false} dark={true} />
        </div>
      </div>

      {loading && <Loading />}

      <div className="sectionBanner">
        <div className="mb-3 mb-md-5">
          <div className="title-container logo-container d-flex justify-content-center align-items-center">
            <h2 className="my-0 my-md-3">
              {translations.welcomeMessage}
              <span className="manropeLight">resilien</span>
              <span className="poppinsbold">TEST</span>
              <span className="manropeLight text-bottom">&reg;</span>!
            </h2>
          </div>
          <div className="content-img-section">
            <img src={banner1} alt="menuIcon" />
          </div>
        </div>
      </div>

      <div>
        <div className="my-3 my-md-5">
          <h3 className="my-0 my-md-3 text-center">
            <strong className="manropeBold">{translations.instructions}</strong>
          </h3>
          <h3 className="my-0 my-md-3 pb-5 text-center">
            {translations.instructionsTest}
          </h3>
          <h3 className="text-center">
            <strong className="manropeBold p-5">
              56 {translations.questions}
            </strong>
            <strong className="manropeBold">{translations.timeAmount} </strong>
            25 {translations.minutes}
          </h3>
        </div>
      </div>

      <div className="d-flex justify-content-start text-center">
        <div className="contentBtn align-items-center pb-5">
          <span className="botonComenzar" onClick={() => gotoTest(id)}>
            {translations.beginTest}
          </span>
        </div>
      </div>
    </div>
  );
}
