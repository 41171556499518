let endpoint = {
  Account: "Account",
  AccountLogin: "Account/Login",
  AccountRegister: "Account/Register",
  HealthCheckTokenStatus: "HealthCheck/TokenStatus",
  CheckAdmin: "HealthCheck/CheckAdmin",
  ProcessPayment: "Payment/Resilientest",
  Payment: "Payment/",
  RequestPasswordChange: "Account/RequestPasswordChange",
  ValidatePasswordCode: "Account/ValidateCode",
  ChangePassword: "Account/ChangePassword",
  Notifications: "Account/Notifications",
  TokenStatus: "User/TokenStatus",
  Assessment: "Assessment",
  UserAssessment: "UserAssessment",
  PersonalInfo: "User/PersonalInformation",
  User: "User",
  Settings: "settings",
  Paypal: "Paypal/Orders",
};

const generateEndpointUrl = (name, apiVersion) => {
  let suffix = endpoint[name] || "";

  let url = "/api/" + suffix;

  return url;
};

export default generateEndpointUrl;
