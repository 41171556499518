import React from "react";

import Modal from "./Modal";
import "./Modal.scss";
import done from "../../../assets/icons/Precaucion.png";
import useTranslation from "../../translations/translations";

const WarningModal = (props) => {
  const translations = useTranslation();

  return (
    <Modal
      show={props.show}
      modalClosed={props.closeFnx}
      closeIcon={true}
      className={"text-center"}
    >
      <div className="row">
        <p className="title">
          resilien<strong>TEST</strong>
        </p>
      </div>
      <div className="row justify-content-center">
        <hr></hr>
      </div>
      <div className="row justify-content-center">
        <h2>
          <strong>
            {translations.warningModal.questionNeedsAnswer}
            <br />
            {translations.warningModal.continueMessage}
          </strong>
        </h2>
      </div>
      <div className="row justify-content-center">
        <img className="img-warning" src={done} alt="sucess" />
      </div>
    </Modal>
  );
};

export default WarningModal;
