import React from "react";
import Backdrop from "../backdrop/Backdrop";
import "./Modal.scss";
import { useImagesTranslation } from "../../translations/translations";

const Modal = (props) => {
  const images = useImagesTranslation();

  return (
    <Backdrop
      show={props.show}
      clicked={props.modalClosed}
      className={props.backDropClass}
    >
      <div
        className={"Modal " + (props.className ? props.className : "")}
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0",
        }}
      >
        {props.closeIcon && (
          <span className="close-icon" onClick={props.modalClosed}>
            <img src={images.close} className="btn-img" alt="close" />
          </span>
        )}
        {props.children}
      </div>
    </Backdrop>
  );
};

export default Modal;
