import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import { Link, useHistory } from "react-router-dom";
const AdminPage = () => {
  const auth = useAuth();
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(null);
  useEffect(() => {
    const checkAdmin = async () => {
      const _auth = auth;
      var isAdmin = await _auth.checkAdmin();
      return isAdmin;
    };

    checkAdmin().then((isAdmin) => {
      setIsAdmin(isAdmin);
    });
  }, []);

  useEffect(() => {
    if (isAdmin != null) {
      if (!isAdmin) history.push("/account");
    }
  }, [isAdmin]);

  if (isAdmin === null || !isAdmin) return <h5>no content</h5>;

  return (
    <>
      <div id="main-content" className="container content mt-4 min-vh-100">
        <div className="row">
          <div className="col-12">
            <h3>Bienvenido Administrador</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              A continuación te mostramos las opciones disponibles que tenemos
              para ti
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Link to="/statusReport">Consultar Assessments</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Link to="/report">Descargar assessments completos</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
