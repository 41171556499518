import React, { useEffect, useState } from "react";
import "./Report.scss";
import httpClient from "../Utils/AxiosHttpClient";
import generateEndpointUrl from "../Utils/apiEndpointUrls";
import Loading from "../components/infrastructure/loadingAnimation";
import { useAuth } from "../hooks/AuthProvider";
import { Link, useHistory } from "react-router-dom";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
const StatusReport = () => {
  const auth = useAuth();
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(null);
  const languages = [
    { key: "", value: "Todos" },
    { key: "es", value: "Español" },
    { key: "en", value: "Inglés" },
  ];
  const statusList = [
    { key: "", value: "Todos" },
    { key: "Paid", value: "Pagado" },
    { key: "Completed", value: "Completo" },
    { key: "Created", value: "Creado" },
  ];
  const data = {
    columns: [
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Nombre",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Idioma",
        field: "language",
        sort: "asc",
        width: 100,
      },
      {
        label: "Nacionalidad",
        field: "Nationality",
        sort: "asc",
        width: 100,
      },
      {
        label: "Profesión",
        field: "occupation",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edad",
        field: "ageRange",
        sort: "asc",
        width: 200,
      },
      {
        label: "Fecha",
        field: "createdDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Cupón",
        field: "couponCode",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
    ],
  };
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    const checkAdmin = async () => {
      const _auth = auth;
      var isAdmin = await _auth.checkAdmin();
      return isAdmin;
    };

    checkAdmin().then((isAdmin) => {
      setIsAdmin(isAdmin);
    });
  }, []);

  useEffect(() => {
    if (isAdmin != null) {
      if (!isAdmin) history.push("/");
    }
  }, [isAdmin]);

  const getFirstDay = () => {
    const date = new Date();
    return `${date.getFullYear()}-${date
      .getMonth()
      .toString()
      .padStart(2, "0")}-01`;
  };
  const [assessments, setAssessments] = useState([]);
  const getLastDay = () => {
    const date = new Date();
    const nextDate = new Date(date.getFullYear(), date.getMonth(), 0);
    return `${nextDate.getFullYear()}-${(nextDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${nextDate.getDate().toString().padStart(2, "0")}`;
  };
  const [filter, setFilter] = useState({
    startDate: getFirstDay(),
    endDate: getLastDay(),
    language: "",
    email: "",
    coupon: "",
    status: "",
  });
  const [loading, setLoading] = useState(false);
  const setValue = (name, value) => {
    setFilter((filt) => ({
      ...filt,
      [name]: value,
    }));
  };
  const getResults = async () => {
    var url = `${generateEndpointUrl(
      "UserAssessment"
    )}/StatusReportPage?startDate=${filter.startDate}&endDate=${
      filter.endDate
    }&language=${filter.language}&email=${filter.email}&coupon=${
      filter.coupon
    }&status=${filter.status}`;
    let response = await httpClient.getData(url);
    if (response.status === 200 || response.data) {
      return response.data;
    }
  };
  const onClickSearch = () => {
    setLoading(true);
    getResults()
      .then((results) => {
        setAssessments(results);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const calculatePagination = () => {
      if (
        assessments !== undefined &&
        assessments !== null &&
        assessments.length > 0
      ) {
        const total = Math.ceil(assessments.length / itemsPerPage);
        setTotalPages(total);
        setSelectedPage(1);
      }
    };
    calculatePagination();
  }, [assessments]);
  useEffect(() => {
    const calculateItems = () => {
      if (assessments === undefined) {
        setSelectedItems([]);
        return;
      }
      let page = selectedPage < 0 ? 1 : Math.min(selectedPage, totalPages);
      const start = (page - 1) * itemsPerPage;
      const end = Math.min(start + itemsPerPage, assessments.length);
      setSelectedItems(assessments.slice(start, end));
    };
    calculateItems();
  }, [selectedPage, totalPages, assessments]);

  if (isAdmin === null || !isAdmin) return <h5>no content</h5>;

  return (
    <>
      {loading && <Loading />}
      <div id="main-content" className="container content mt-4">
        <div className="row">
          <div className="col-12">
            <Link to="/admin">Regresar a página de inicio</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h4>Consulta aquí los cuestionarios.</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <div className="row">
              <div className="col-12">
                <label className="input-label">Fecha inicial</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setValue("startDate", e.target.value)}
                  value={filter.startDate}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <div className="row">
              <div className="col-12">
                <label className="input-label">Fecha final</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setValue("endDate", e.target.value)}
                  value={filter.endDate}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
            <div className="row">
              <div className="col-12">
                <label className="input-label">Idioma</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <select
                  className="form-control"
                  name="language"
                  value={filter.language}
                  onChange={(e) => setValue("language", e.target.value)}
                >
                  {languages.map(({ key, value }, index) => (
                    <option key={key + "g"} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
            <div className="row">
              <div className="col-12">
                <label className="input-label">Status</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <select
                  className="form-control"
                  name="language"
                  value={filter.status}
                  onChange={(e) => setValue("status", e.target.value)}
                >
                  {statusList.map(({ key, value }, index) => (
                    <option key={key + "g"} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
            <div className="row">
              <div className="col-12">
                <label className="input-label">Cupón</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setValue("coupon", e.target.value)}
                  value={filter.coupon.toUpperCase()}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <div className="row">
              <div className="col-12">
                <label className="input-label">Email</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setValue("email", e.target.value)}
                  value={filter.email}
                ></input>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onClickSearch}
            >
              Buscar
            </button>
          </div>
        </div>
        <div className="min-vh-76">
          {assessments !== undefined &&
            assessments != null &&
            assessments.length > 0 && (
              <>
                <MDBTable responsive paging striped>
                  <MDBTableHead>
                    <tr>
                      {data.columns.map(({ label, field }, index) => (
                        <th scope="col">{label}</th>
                      ))}
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {selectedItems.map((obj, index) => (
                      <tr>
                        <th scope="row">{obj.email}</th>
                        <td>{obj.name}</td>
                        <td>{obj.language}</td>
                        <td>{obj.nationality}</td>
                        <td>{obj.occupation}</td>
                        <td>{obj.ageRange}</td>
                        <td>{obj.createdDate}</td>
                        <td>{obj.couponCode}</td>
                        <td>{obj.status}</td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
                <div className="row">
                  <div
                    data-test="datatable-info"
                    className="col-sm-12 col-md-5"
                  >
                    <div
                      className="dataTables_info"
                      role="status"
                      aria-live="polite"
                    >
                      Mostrando {(selectedPage - 1) * itemsPerPage + 1}-
                      {(selectedPage - 1) * itemsPerPage + selectedItems.length}
                      /{assessments.length}
                    </div>
                  </div>
                  <div
                    data-test="datatable-pagination"
                    className="col-sm-12 col-md-7"
                  >
                    <div className="dataTables_paginate">
                      <ul data-test="pagination" className="pagination">
                        <li
                          data-test="page-item"
                          className={`page-item ${
                            selectedPage > 1 ? "" : "disabled"
                          }`}
                        >
                          <a
                            aria-label="Previous"
                            className="page-link page-link clickable"
                            onClick={() => setSelectedPage(selectedPage - 1)}
                          >
                            <span>Anterior</span>
                          </a>
                        </li>
                        <li
                          data-test="page-item"
                          className="page-item disabled d-flex align-items-center mx-3"
                        >
                          Página {selectedPage}/{totalPages}
                        </li>
                        <li
                          data-test="page-item"
                          className={`page-item ${
                            selectedPage < totalPages ? "" : "disabled"
                          }`}
                        >
                          <a
                            aria-label="Next"
                            className="page-link page-link clickable"
                            onClick={() => setSelectedPage(selectedPage + 1)}
                          >
                            <span>Siguiente</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          {(assessments === undefined ||
            assessments === null ||
            assessments.length === 0) && (
            <div className="row mt-5">
              <div className="col-12">
                <h4>Aún no tenemos registros para la búsqueda</h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StatusReport;
