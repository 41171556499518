import { useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/AuthProvider";
import useTranslation from "../translations/translations";
import "./Hamburger.scss";

export default function Hamburger({ dark }) {
  const strings = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const toggleHamburger = () => {
    setIsOpen(!isOpen);
  };
  const auth = useAuth();

  function renderMenuItems() {
    return menuItemsContent();
  }

  function signOut() {
    console.log("CLOSE");
    auth.logout();
  }

  function menuItemsContent() {
    return (
      <ul className={isOpen ? "isOpen" : ""}>
        {auth.isAuthenticated === true ? (
          <>
            <li>
              <NavLink tag={Link} to="/account">
                {strings.h_myaccount}
              </NavLink>
            </li>
            <li>
              <NavLink tag={Link} to="/Confirm">
                {strings.h_myshopping}
              </NavLink>
            </li>
            <li>
              <NavLink tag={Link} to="" onClick={signOut}>
                {strings.h_logout}
              </NavLink>
            </li>
          </>
        ) : (
          <>
            <li>
              <NavLink tag={Link} to="/Login">
                {strings.h_login}
              </NavLink>
            </li>
          </>
        )}
      </ul>
    );
  }

  return (
    <>
      <div className={"navigation " + (dark ? "dark" : "")}>
        <div className="hamburger" onClick={toggleHamburger}>
          <div className="burger" />
          <div className="burger " />
          <div className="burger" />
        </div>
        <div className={"bordera " + (isOpen ? "isOpen" : "")} />
        {renderMenuItems()}
      </div>
    </>
  );
}
